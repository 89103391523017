import React, { useEffect, useState } from 'react'
import {
	useSelector,
	//  useDispatch
} from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { PageHeader, Button } from 'antd'
// import authActions from '../modules/auth/redux'
import { ReactComponent as Logo } from '../assets/images/logo.svg'

const SignedOutHome = (props) => {
	// const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (user) {
			props.history.push({
				pathname: '/emailVerificationRequired',
				state: props.location.state,
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		// Add event listener
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div>
			<StyledPageHeader
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
						style={{ pointerEvents: 'none' }}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				extra={[
					<StyledSignUpNavButton
						key="1"
						type="default"
						style={{ marginRight: '2em' }}
					>
						<Link
							to={{
								pathname: 'signUp',
								state: props.location.state,
							}}
						>
							<span style={{ color: '#424242' }}>Sign Up</span>
						</Link>
					</StyledSignUpNavButton>,
					<Button
						key="2"
						type="primary"
						style={{ border: '1px solid #ffffffD9' }}
					>
						<Link
							to={{
								pathname: '/signIn',
								state: props.location.state,
							}}
						>
							<span style={{ color: '#ffffffD9' }}>Sign In</span>
						</Link>
					</Button>,
				]}
			/>
			<StyledHero height={windowSize.height}>
				<div>
					<StyledHeroHeader className="noselect">
						League of Bulls
					</StyledHeroHeader>
					<StyledHeroSubHeader level={4} className="noselect">
						See how your portfolio is doing compared to your friends!
					</StyledHeroSubHeader>
					<StyledHeroButton
						to={{
							pathname: '/signIn',
							state: props.location.state,
						}}
					>
						START TODAY
					</StyledHeroButton>
				</div>
			</StyledHero>
		</div>
	)
}

export default withRouter(SignedOutHome)

const StyledPageHeader = styled(PageHeader)`
	margin-left: auto;
	margin-right: auto;
	max-width: 92vw;
	z-index: 9;
	@media (min-width: 768px) {
		max-width: 82vw;
	}
`

const StyledHero = styled('section')`
	/* Sizing */
	position: absolute;
	top: 0;
	width: 100%;
	height: ${(props) =>
		props.height ? `${props.height}px` : `${window.innerHeight}px`};
	/* Flexbox stuff */
	display: flex;
	justify-content: center;
	align-items: center;

	/* Text styles */
	text-align: center;
	color: #fff;

	/* Background styles */
	background-image: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		),
		url('https://codetheweb.blog/assets/img/posts/full-image-hero/image.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
`
const StyledHeroButton = styled(Link)`
	/* Positioning and sizing */
	/* display: block; */
	width: 420px;
	/* Padding and margins */
	padding: 0.2em;
	margin-top: 50px;
	max-width: 82vw;
	margin-left: auto;
	margin-right: auto;
	/* Text styles */
	color: #ffffffd9;
	display: flex;
	justify-content: center;
	align-items: center;

	text-decoration: none;
	font-size: 1.6em;
	font-weight: 600;
	/* Border styles */
	border: 1px solid #ffffffd9;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.42);
	/* Background styles */
	/* background-color: rgba(0, 200, 5); */
	transition: 0.22s;
	:hover {
		color: #424242;
		background-color: #ffffffd9;
	}
`

const StyledHeroHeader = styled('h1')`
	color: #ffffff;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 3em;
	@media (min-width: 768px) {
		font-size: 5em;
	}
`
const StyledHeroSubHeader = styled('h3')`
	color: #ffffff;
	max-width: 92vw;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 1.4em;
	@media (min-width: 768px) {
		font-size: 2em;
	}
`
const StyledSignUpNavButton = styled(Button)`
	background-color: #ffffffd9;
	color: #424242;
	border-color: rgba(255, 255, 255, 0);
	transition: 0.22s;
	:hover {
		background-color: #ffffffd9;
	}
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #ffffff;
	:hover {
		fill: #00c805;
	}
`
