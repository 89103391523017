import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
// import { logger } from '@monorepo/shared-client/utils'

const AuthLoading = (props) => {
	/// / Redux States
	const { user } = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States

	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (user) {
			props.history.push({
				pathname: '/emailVerificationRequired',
				state: props.location.state,
			})
		} else {
			props.history.push({
				pathname: '/signedOutHome',
				state: props.location.state,
			})
		}
	}

	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return <StyledContainer>{null}</StyledContainer>
}

export default withRouter(AuthLoading)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`
