import { put, takeLatest, call } from 'redux-saga/effects'
import redditActions, { redditTypes } from './redux'
import * as API from './api'

/* ------------- Worker Sagas ------------- */
function* retrieveShowerThoughtSaga() {
	try {
		// sets loading to true
		yield put(redditActions.retrieveShowerThoughtPending())
		// makes api call
		const response = yield call(API.retrieveShowerThought)
		// dispatch a success action to the store with the new showerThought
		yield put(redditActions.retrieveShowerThoughtFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(redditActions.retrieveShowerThoughtRejected(error))
	}
}

/* ------------- Watcher Sagas ------------- */

const redditSagas = [
	// some sagas only receive an action
	takeLatest(
		redditTypes.RETRIEVE_SHOWER_THOUGHT_REQUEST_START,
		retrieveShowerThoughtSaga
	),
]

export default redditSagas
