import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { OFFLINE, ONLINE } from 'redux-offline-queue'
import { Detector } from 'react-detect-offline'
import RootNavigation from '../navigation/RootNavigation'
import ReduxPersist from '../config/reduxPersist'
import startupActions from '../modules/startup/redux'
import networkActions from '../modules/network/redux'
import authActions from '../modules/auth/redux'

const RootComponent = () => {
	const dispatch = useDispatch()

	/// / Redux States
	const { online: networkOnline } = useSelector((state) => state.network)

	/// //////////////////////////////////////////
	/// / Local States

	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const handleNetworkChange = (newStatus) => {
		if (newStatus !== networkOnline) {
			if (newStatus) {
				dispatch(networkActions.networkOnline())
				dispatch({ type: ONLINE })
			} else {
				dispatch(networkActions.networkOffline())
				dispatch({ type: OFFLINE })
			}
		}
	}

	const preloader = document.querySelector('.preloader')

	const removePreloaderClass = () => {
		if (preloader) {
			preloader.classList.remove('preloader')
		}
	}
	const addPreloaderHideClass = () => {
		if (preloader) {
			preloader.classList.add('preloader-hide')
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		// if redux persist is not active fire startup action
		if (!ReduxPersist.active) {
			dispatch(startupActions.startup())
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	dispatch(authActions.isSignInWithEmailLinkRequestStart())
	// }, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(authActions.authStateChangedListenerRequestStart({ dispatch }))
		return () => {}
	}, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		removePreloaderClass()
		addPreloaderHideClass()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<Router>
			<RootNavigation />
			<Detector
				render={({ online }) => (
					<div>
						{null}
						{handleNetworkChange(online)}
					</div>
				)}
			/>
		</Router>
	)
}

export default RootComponent
