import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, message, PageHeader, Popover, Spin, Typography } from 'antd'
import { Form, FormItem, Input } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import flatten from 'flat'
import { AiOutlineLock, AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import {
	IoMdInformationCircleOutline,
	IoIosCheckmarkCircleOutline,
} from 'react-icons/io'
// import { logger } from '@monorepo/shared-client/utils'
// import { reloadUser } from '../../modules/auth/api'
import { firebaseAuth } from '../../config/firebase'
import { appGlobalTheme as theme } from '../../app.json'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'

const { Title, Text } = Typography

const ResetPassword = (props) => {
	/// / Redux States
	const { user } = useSelector((state) => state.auth)

	/// //////////////////////////////////////////
	/// / Local States
	const [actionCodeCheckStatus, setActionCodeCheckStatus] = useState(null)
	const [actionCodeCheckPending, setActionCodeCheckPending] = useState(true)
	const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
	const [passwordResetPending, setPasswordResetPending] = useState(false)
	const [passwordResetError, setPasswordResetError] = useState(null)

	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const FormikPatchTouched = () => {
		const {
			errors,
			setFieldTouched,
			isSubmitting,
			isValidating,
		} = useFormikContext()
		useEffect(() => {
			if (isSubmitting && !isValidating) {
				// eslint-disable-next-line
				for (const path of Object.keys(flatten(errors))) {
					setFieldTouched(path, true, false)
				}
			}
		}, [errors, isSubmitting, isValidating, setFieldTouched])
		return null
	}

	const validatePassword = (value) => {
		let error
		if (!value) {
			error = 'Password is required!'
		} else if (typeof value !== 'string') {
			error = 'Password must be a string!'
		} else if (value.toUpperCase() === value) {
			error = 'Password must include at least 1 lowercase letter!'
		} else if (value.toLowerCase() === value) {
			error = 'Password must include at least 1 uppercase letter!'
		} else if (!/\d/.test(value)) {
			error = 'Password must include at least 1 number!'
		} else if (value.length < 8) {
			error = 'Password must be at least 8 characters!'
		}
		return error
	}

	const handleRedirectToSignIn = () => {
		const { history } = props
		history.replace(`/signIn`)
	}

	const handleRedirectToForgotPassword = () => {
		const { history } = props
		history.replace('/forgotPassword')
	}

	const onFinish = async (values) => {
		const { password } = values
		const { actionCode } = props
		try {
			// Save the new password.
			setPasswordResetPending(true)
			await firebaseAuth.confirmPasswordReset(actionCode, password)
			// Password reset has been confirmed and new password updated.
			setPasswordResetSuccess(true)
			message.success('Password reset successful!', 5)
			setPasswordResetPending(false)
		} catch (error) {
			setPasswordResetPending(false)
			// Error occurred during confirmation. The code might have expired or the
			// password is too weak.
			message.error(error.message, 5)
			setPasswordResetError(error.message)
			setPasswordResetSuccess(false)
		}
	}

	const checkActionCode = async () => {
		try {
			// Try to apply the email verification code.
			await firebaseAuth.verifyPasswordResetCode(props.actionCode)
			setActionCodeCheckStatus(true)
			setActionCodeCheckPending(false)
		} catch (error) {
			// Code is invalid or expired.
			setActionCodeCheckStatus(null)
			setActionCodeCheckPending(false)
		}
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		checkActionCode()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Password Reset
				</Title>
				{actionCodeCheckPending && (
					<StyledPendingContainer>
						<Spin size="large" />
						<div style={{ marginTop: '2vh' }}>verifying link...</div>
					</StyledPendingContainer>
				)}
				{!actionCodeCheckPending && !actionCodeCheckStatus && (
					<StyledContentContainer>
						<h3 style={{ color: 'tomato' }}>
							Verification Code is invalid or has expired.
						</h3>
						<Button basic onClick={handleRedirectToForgotPassword}>
							Request New Password Reset Email
						</Button>
					</StyledContentContainer>
				)}
				{!actionCodeCheckPending &&
					actionCodeCheckStatus &&
					passwordResetSuccess && (
						<StyledContentContainer>
							<div>
								<IoIosCheckmarkCircleOutline
									style={{
										fontSize: '4em',
										color: '#52c41a',
										marginBottom: '2vh',
									}}
								/>
							</div>
							<h3 style={{ color: '#52c41a' }}>Password Reset Successful.</h3>
							<Button basic onClick={handleRedirectToSignIn}>
								Continue To Sign In
							</Button>
						</StyledContentContainer>
					)}
				{!actionCodeCheckPending &&
					actionCodeCheckStatus &&
					!passwordResetSuccess && (
						<Formik
							initialValues={{
								password: '',
							}}
							onSubmit={onFinish}
						>
							{() => (
								<StyledForm layout="vertical">
									<FormikPatchTouched />

									<div>
										<FormItem
											name="password"
											label={
												window.screen.width >= 768 ? (
													<div
														className="noselect"
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<span>New Password&nbsp;</span>
														<Popover
															title={
																<Text
																	className="noselect"
																	style={{ fontSize: '0.82em' }}
																>
																	Your password should contain as least:
																</Text>
															}
															content={
																<ul className="noselect">
																	<li>
																		<Text style={{ fontSize: '0.72em' }}>
																			1 lowercase letter
																		</Text>
																	</li>
																	<li>
																		<Text style={{ fontSize: '0.72em' }}>
																			1 uppercase letter
																		</Text>
																	</li>
																	<li>
																		<Text style={{ fontSize: '0.72em' }}>
																			1 number
																		</Text>
																	</li>
																	<li>
																		<Text style={{ fontSize: '0.72em' }}>
																			12 characters
																		</Text>
																	</li>
																</ul>
															}
														>
															<IoMdInformationCircleOutline
																style={{
																	color:
																		theme === 'dark'
																			? 'rgba(255,255,255,.65)'
																			: 'rgba(0,0,0,.65)',
																	fontSize: '1.2em',
																	verticalAlign: 'center',
																}}
															/>
														</Popover>
													</div>
												) : null
											}
											labelalign="left"
											required
											validate={validatePassword}
										>
											<Input.Password
												name="password"
												placeholder="New Password"
												autoComplete="new-password"
												disabled={passwordResetPending}
												size="large"
												prefix={<AiOutlineLock />}
												style={{
													width: '420px',
													maxWidth: '75vw',
												}}
												iconRender={(visible) =>
													visible ? <AiFillEye /> : <AiFillEyeInvisible />
												}
											/>
										</FormItem>
										<StyledError>{passwordResetError || ''}</StyledError>
										<Button
											type="primary"
											size="large"
											block
											htmlType="submit"
											loading={passwordResetPending}
											style={{ marginTop: '25px' }}
										>
											Reset Password
										</Button>
										<StyledSecondaryFooter className="noselect">
											<Text>
												<Link
													to={{
														pathname: '/signIn',
														state: props.location.state,
													}}
												>
													Continue To Sign In
												</Link>
											</Text>
										</StyledSecondaryFooter>
									</div>
								</StyledForm>
							)}
						</Formik>
					)}
			</StyledContainer>
		</div>
	)
}

export default withRouter(ResetPassword)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`
const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
	box-shadow: ${() =>
		theme === 'dark'
			? ''
			: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'};
	background-color: ${() =>
		theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'inherent'};
`

const StyledPendingContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
`
const StyledError = styled('p')`
	color: tomato;
	font-size: 0.82em;
	margin: 0;
	width: 380px;
	max-width: 82vw;
	margin-left: auto;
	margin-right: auto;
`
const StyledSecondaryFooter = styled('div')`
	margin-top: 2vh;
	font-size: 1em;
	max-width: 82vw;
	@media (max-width: 280px) {
		font-size: 0.92em;
	}
`
const StyledContentContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
