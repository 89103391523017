import { createActions, createReducer } from 'reduxsauce'
// import { markActionsOffline } from 'redux-offline-queue'
// import produce from 'immer'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	signIn: { user: null },
	signOut: null,
	signUpUserRequestStart: { data: null },
	signUpUserPending: null,
	signUpUserFulfilled: null,
	signUpUserRejected: ['error'],
	signInUserRequestStart: { data: null },
	signInUserPending: null,
	signInUserFulfilled: null,
	signInUserRejected: ['error'],
	sendSignInLinkToEmailRequestStart: { data: null },
	sendSignInLinkToEmailPending: null,
	sendSignInLinkToEmailFulfilled: null,
	sendSignInLinkToEmailRejected: ['error'],
	saveUserLocally: ['user'],
	signOutUserLocally: null,
	signOutUserRequestStart: { dispatch: null },
	signOutUserPending: null,
	signOutUserFulfilled: null,
	signOutUserRejected: ['error'],
	authStateChangedListenerRequestStart: { dispatch: null },
	authStateChangedListenerPending: null,
	authStateChangedListenerFulfilled: null,
	authStateChangedListenerRejected: ['error'],
	isSignInWithEmailLinkRequestStart: null,
	isSignInWithEmailLinkPending: null,
	isSignInWithEmailLinkFulfilled: null,
	isSignInWithEmailLinkRejected: ['error'],
	sendPasswordResetEmailRequestStart: { data: null },
	sendPasswordResetEmailPending: null,
	sendPasswordResetEmailFulfilled: null,
	sendPasswordResetEmailRejected: ['error'],
	clearResetPasswordEmailSentLocally: null,
	authWithRobinhoodRequestStart: { data: null },
	authWithRobinhoodPending: null,
	authWithRobinhoodFulfilled: ['response'],
	authWithRobinhoodRejected: ['error'],
	clearAuthWithRobinhoodStatusLocally: null,
	clearAllAuthErrorsLocally: null,
})

export const authTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
	user: null,
	signUpUserStatus: null,
	signUpUserPending: false,
	signUpUserError: null,
	signInUserStatus: null,
	signInUserPending: false,
	signInUserError: null,
	sendSignInLinkToEmailStatus: null,
	sendSignInLinkToEmailPending: false,
	sendSignInLinkToEmailError: null,
	signOutUserPending: false,
	signOutUserError: null,
	authStateChangedListener: false,
	authStateChangedListenerPending: false,
	authStateChangedListenerError: null,
	isSignInWithEmailLinkStatus: false,
	isSignInWithEmailLinkPending: false,
	isSignInWithEmailLinkError: null,
	sendPasswordResetEmailPending: false,
	sendPasswordResetEmailError: null,
	resetPasswordEmailSent: false,
	authWithRobinhoodStatus: null,
	authWithRobinhoodPending: false,
	authWithRobinhoodError: null,
}

export const signUpUserPending = (state = INITIAL_STATE) => {
	return {
		...state,
		signUpUserPending: true,
	}
}

export const signUpUserFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		signUpUserPending: false,
		signUpUser: true,
		signUpUserError: null,
	}
}

export const signUpUserRejected = (state = INITIAL_STATE, action) => {
	return {
		...state,
		signUpUserPending: false,
		signUpUserError:
			action.error && action.error.message
				? action.error.message
				: 'Error signing up user.',
	}
}

export const signInUserPending = (state = INITIAL_STATE) => {
	return {
		...state,
		signInUserPending: true,
	}
}

export const signInUserFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		signInUserPending: false,
		signInUser: true,
		signInUserError: null,
	}
}

export const signInUserRejected = (state = INITIAL_STATE, action) => {
	return {
		...state,
		signInUserPending: false,
		signInUserError:
			action.error && action.error.message
				? action.error.message
				: 'Error signing in user.',
	}
}

export const sendSignInLinkToEmailPending = (state = INITIAL_STATE) => {
	return {
		...state,
		sendSignInLinkToEmailPending: true,
	}
}

export const sendSignInLinkToEmailFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		sendSignInLinkToEmailPending: false,
		sendSignInLinkToEmailStatus: true,
		sendSignInLinkToEmailError: null,
	}
}

export const sendSignInLinkToEmailRejected = (
	state = INITIAL_STATE,
	action
) => {
	return {
		...state,
		sendSignInLinkToEmailPending: false,
		sendSignInLinkToEmailError:
			action.error && action.error.message
				? action.error.message
				: 'Error sending sign in email.',
	}
}

export const saveUserLocally = (state = INITIAL_STATE, action) => {
	return {
		...state,
		user: { ...state.user, ...action.user },
	}
}

export const signOutUserLocally = (state = INITIAL_STATE) => {
	return {
		...state,
		user: null,
	}
}

export const signOutUserPending = (state = INITIAL_STATE) => {
	return { ...state, signOutUserPending: true }
}

export const signOutUserFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		signOutUserPending: false,
		signOutUserError: null,
	}
}

export const signOutUserRejected = (state = INITIAL_STATE, action) => {
	return {
		...state,
		signOutUserPending: false,
		signOutUserError:
			action.error && action.error.message
				? action.error.message
				: 'Error signing out. Please retry.',
	}
}

export const authStateChangedListenerPending = (state = INITIAL_STATE) => {
	return {
		...state,
		authStateChangedListenerPending: true,
	}
}

export const authStateChangedListenerFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		authStateChangedListenerPending: false,
		authStateChangedListener: true,
		authStateChangedListenerError: null,
	}
}

export const authStateChangedListenerRejected = (
	state = INITIAL_STATE,
	action
) => {
	return {
		...state,
		authStateChangedListenerPending: false,
		authStateChangedListenerError:
			action.error && action.error.message
				? action.error.message
				: 'Error listening auth state.',
	}
}

export const isSignInWithEmailLinkPending = (state = INITIAL_STATE) => {
	return {
		...state,
		isSignInWithEmailLinkPending: true,
		isSignInWithEmailLinkError: null,
	}
}

export const isSignInWithEmailLinkFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		isSignInWithEmailLinkPending: false,
		isSignInWithEmailLinkStatus: true,
		isSignInWithEmailLinkError: null,
	}
}

export const isSignInWithEmailLinkRejected = (
	state = INITIAL_STATE,
	action
) => {
	return {
		...state,
		isSignInWithEmailLinkPending: false,
		isSignInWithEmailLinkError:
			action.error && action.error.message
				? action.error.message
				: 'Error signing in user.',
	}
}

export const sendPasswordResetEmailPending = (state = INITIAL_STATE) => {
	return { ...state, sendPasswordResetEmailPending: true }
}

export const sendPasswordResetEmailFulfilled = (state = INITIAL_STATE) => {
	return {
		...state,
		resetPasswordEmailSent: true,
		sendPasswordResetEmailPending: false,
		sendPasswordResetEmailError: null,
	}
}

export const sendPasswordResetEmailRejected = (
	state = INITIAL_STATE,
	action
) => {
	return {
		...state,
		sendPasswordResetEmailPending: false,
		sendPasswordResetEmailError:
			action.error && action.error.message
				? action.error.message
				: 'Error occurred at password reset request. please retry.',
	}
}

export const clearResetPasswordEmailSentLocally = (state = INITIAL_STATE) => {
	return {
		...state,
		resetPasswordEmailSent: false,
	}
}

export const authWithRobinhoodPending = (state = INITIAL_STATE) => {
	return {
		...state,
		authWithRobinhoodPending: true,
	}
}

export const authWithRobinhoodFulfilled = (state = INITIAL_STATE, action) => {
	return {
		...state,
		authWithRobinhoodPending: false,
		authWithRobinhoodStatus: { ...action.response },
		authWithRobinhoodError: null,
	}
}

export const authWithRobinhoodRejected = (state = INITIAL_STATE, action) => {
	return {
		...state,
		authWithRobinhoodPending: false,
		authWithRobinhoodError:
			action.error && action.error.message
				? action.error.message
				: 'Error connecting with Robinhood.',
	}
}

export const clearAuthWithRobinhoodStatusLocally = (state = INITIAL_STATE) => {
	return {
		...state,
		authWithRobinhoodStatus: null,
	}
}

export const clearAllAuthErrorsLocally = (state = INITIAL_STATE) => {
	return {
		...state,
		signUpUserError: null,
		signInUserError: null,
		sendSignInLinkToEmailError: null,
		signOutUserError: null,
		authStateChangedListenerError: null,
		isSignInWithEmailLinkError: null,
		authWithRobinhoodError: null,
	}
}
// map our action types to our reducer functions
export const HANDLERS = {
	[Types.SIGN_UP_USER_PENDING]: signUpUserPending,
	[Types.SIGN_UP_USER_FULFILLED]: signUpUserFulfilled,
	[Types.SIGN_UP_USER_REJECTED]: signUpUserRejected,
	[Types.SIGN_IN_USER_PENDING]: signInUserPending,
	[Types.SIGN_IN_USER_FULFILLED]: signInUserFulfilled,
	[Types.SIGN_IN_USER_REJECTED]: signInUserRejected,
	[Types.SEND_SIGN_IN_LINK_TO_EMAIL_PENDING]: sendSignInLinkToEmailPending,
	[Types.SEND_SIGN_IN_LINK_TO_EMAIL_FULFILLED]: sendSignInLinkToEmailFulfilled,
	[Types.SEND_SIGN_IN_LINK_TO_EMAIL_REJECTED]: sendSignInLinkToEmailRejected,
	[Types.SAVE_USER_LOCALLY]: saveUserLocally,
	[Types.SIGN_OUT_USER_LOCALLY]: signOutUserLocally,
	[Types.SIGN_OUT_USER_PENDING]: signOutUserPending,
	[Types.SIGN_OUT_USER_FULFILLED]: signOutUserFulfilled,
	[Types.SIGN_OUT_USER_REJECTED]: signOutUserRejected,
	[Types.AUTH_STATE_CHANGED_LISTENER_PENDING]: authStateChangedListenerPending,
	[Types.AUTH_STATE_CHANGED_LISTENER_FULFILLED]: authStateChangedListenerFulfilled,
	[Types.AUTH_STATE_CHANGED_LISTENER_REJECTED]: authStateChangedListenerRejected,
	[Types.IS_SIGN_IN_WITH_EMAIL_LINK_PENDING]: isSignInWithEmailLinkPending,
	[Types.IS_SIGN_IN_WITH_EMAIL_LINK_FULFILLED]: isSignInWithEmailLinkFulfilled,
	[Types.IS_SIGN_IN_WITH_EMAIL_LINK_REJECTED]: isSignInWithEmailLinkRejected,
	[Types.SEND_PASSWORD_RESET_EMAIL_PENDING]: sendPasswordResetEmailPending,
	[Types.SEND_PASSWORD_RESET_EMAIL_FULFILLED]: sendPasswordResetEmailFulfilled,
	[Types.SEND_PASSWORD_RESET_EMAIL_REJECTED]: sendPasswordResetEmailRejected,
	[Types.CLEAR_RESET_PASSWORD_EMAIL_SENT_LOCALLY]: clearResetPasswordEmailSentLocally,
	[Types.AUTH_WITH_ROBINHOOD_PENDING]: authWithRobinhoodPending,
	[Types.AUTH_WITH_ROBINHOOD_FULFILLED]: authWithRobinhoodFulfilled,
	[Types.AUTH_WITH_ROBINHOOD_REJECTED]: authWithRobinhoodRejected,
	[Types.CLEAR_AUTH_WITH_ROBINHOOD_STATUS_LOCALLY]: clearAuthWithRobinhoodStatusLocally,
	[Types.CLEAR_ALL_AUTH_ERRORS_LOCALLY]: clearAllAuthErrorsLocally,
}
export const reducer = createReducer(INITIAL_STATE, HANDLERS)
