const APP_ENV_TYPES = {
	dev: 'dev',
	production: 'production',
}

const COLLECTIONS = {
	accounts: 'accounts',
	connectedAccounts: 'connectedAccounts',
	expiringAuthConnectedAccounts: 'expiringAuthConnectedAccounts',
	system: 'system',
}

const CONNECTED_ACCOUNT_SOURCES = {
	robinhood: 'robinhood',
}

const CONNECTED_ACCOUNT_TYPES = {
	investment: 'investment',
}

const REQUEST_STATUS = {
	pending: 'pending',
	succeeded: 'succeeded',
	failed: 'failed',
}

const DELIMITER = ':'

module.exports = {
	APP_ENV_TYPES,
	CONNECTED_ACCOUNT_SOURCES,
	CONNECTED_ACCOUNT_TYPES,
	COLLECTIONS,
	DELIMITER,
	INTERNAL_FUNCTIONS_NAMES: require('./internalFunctionNames'),
	REQUEST_STATUS,
}
