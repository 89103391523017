import axios from 'axios'
import { logger } from '@monorepo/shared-client/utils'

const handleError = (error) => {
	logger.error('reddit api:', error)
}

export async function retrieveShowerThought() {
	// uses Reddit api to fetch top shower thought
	try {
		const response = await axios.get(
			`https://www.reddit.com/r/showerthoughts/top/.json?count=25`,
			null,
			null
		)

		return response.data.data.children[Math.floor(Math.random() * 24)].data
			.title
	} catch (error) {
		return handleError(error)
	}
}
