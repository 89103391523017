import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Button, Popover, PageHeader, Divider } from 'antd'
import { Form, FormItem, Input } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import flatten from 'flat'
import {
	AiOutlineUser,
	AiOutlineLock,
	AiFillEye,
	AiFillEyeInvisible,
} from 'react-icons/ai'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { FcGoogle } from 'react-icons/fc'
// import { FaFacebookSquare } from 'react-icons/fa'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
import { appGlobalTheme as theme } from '../app.json'
import { ReactComponent as Logo } from '../assets/images/logo.svg'

const { Title, Text } = Typography

const SignUp = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const {
		user,
		// signUpUserStatus,
		signUpUserPending,
		signUpUserError,
	} = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States
	const [provider, setProvider] = useState(null)
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers

	const onFinish = (values) => {
		// logger.log('values:', values)
		dispatch(
			authActions.signUpUserRequestStart({
				data: {
					email: values.email,
					password: values.password,
					provider: 'password',
				},
			})
		)
		setProvider('password')
	}
	const signUpUserWithGoogle = () => {
		dispatch(
			authActions.signUpUserRequestStart({
				data: {
					provider: 'google',
				},
			})
		)
		setProvider('google')
	}

	const FormikPatchTouched = () => {
		const {
			errors,
			setFieldTouched,
			isSubmitting,
			isValidating,
		} = useFormikContext()
		useEffect(() => {
			if (isSubmitting && !isValidating) {
				// eslint-disable-next-line
				for (const path of Object.keys(flatten(errors))) {
					setFieldTouched(path, true, false)
				}
			}
		}, [errors, isSubmitting, isValidating, setFieldTouched])
		return null
	}

	const validateEmail = (value) => {
		let error
		if (!value) {
			error = 'Email is required!'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Invalid email address'
		}
		return error
	}

	const validatePassword = (value) => {
		let error
		if (!value) {
			error = 'Password is required!'
		} else if (typeof value !== 'string') {
			error = 'Password must be a string!'
		} else if (value.toUpperCase() === value) {
			error = 'Password must include at least 1 lowercase letter!'
		} else if (value.toLowerCase() === value) {
			error = 'Password must include at least 1 uppercase letter!'
		} else if (!/\d/.test(value)) {
			error = 'Password must include at least 1 number!'
		} else if (value.length < 8) {
			error = 'Password must be at least 8 characters!'
		}
		return error
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (user) {
			props.history.push({
				pathname: '/emailVerificationRequired',
				state: props.location.state,
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Welcome
				</Title>
				<Formik
					initialValues={{
						email: '',
					}}
					onSubmit={onFinish}
				>
					{() => (
						<StyledForm layout="vertical">
							<FormikPatchTouched />
							<div>
								<Button
									size="large"
									type="primary"
									block
									style={{
										marginTop: '2vh',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										verticalAlign: 'center',
									}}
									onClick={signUpUserWithGoogle}
								>
									<FcGoogle
										style={{
											fontSize: '1.8em',
											marginRight: '1vw',
											backgroundColor: '#ffffff',
											padding: '0.1em',
										}}
									/>
									<span>Continue With Google</span>
								</Button>
								<StyledDivider className="noselect" orientation="center">
									<Text type="secondary">OR</Text>
								</StyledDivider>
								<FormItem
									name="email"
									label={
										window.screen.width >= 768 ? (
											<span className="noselect">Email</span>
										) : null
									}
									labelalign="left"
									required
									validate={validateEmail}
								>
									<Input
										name="email"
										placeholder="Email"
										disabled={signUpUserPending && provider === 'password'}
										size="large"
										prefix={<AiOutlineUser />}
										style={{ width: '420px', maxWidth: '75vw' }}
									/>
								</FormItem>
								<FormItem
									name="password"
									label={
										window.screen.width >= 768 ? (
											<div
												className="noselect"
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<span>Password&nbsp;</span>
												<Popover
													title={
														<Text
															className="noselect"
															style={{ fontSize: '0.82em' }}
														>
															Your password should contain as least:
														</Text>
													}
													content={
														<ul className="noselect">
															<li>
																<Text style={{ fontSize: '0.72em' }}>
																	1 lowercase letter
																</Text>
															</li>
															<li>
																<Text style={{ fontSize: '0.72em' }}>
																	1 uppercase letter
																</Text>
															</li>
															<li>
																<Text style={{ fontSize: '0.72em' }}>
																	1 number
																</Text>
															</li>
															<li>
																<Text style={{ fontSize: '0.72em' }}>
																	12 characters
																</Text>
															</li>
														</ul>
													}
												>
													<IoMdInformationCircleOutline
														style={{
															color:
																theme === 'dark'
																	? 'rgba(255,255,255,.65)'
																	: 'rgba(0,0,0,.65)',
															fontSize: '1.2em',
															verticalAlign: 'center',
														}}
													/>
												</Popover>
											</div>
										) : null
									}
									labelalign="left"
									required
									validate={validatePassword}
								>
									<Input.Password
										name="password"
										placeholder="Password"
										autoComplete="new-password"
										disabled={signUpUserPending && provider === 'password'}
										size="large"
										prefix={<AiOutlineLock />}
										style={{
											width: '420px',
											maxWidth: '75vw',
										}}
										iconRender={(visible) =>
											visible ? <AiFillEye /> : <AiFillEyeInvisible />
										}
									/>
								</FormItem>
								<StyledError>{signUpUserError || ''}</StyledError>
								<Button
									type="primary"
									size="large"
									block
									htmlType="submit"
									loading={signUpUserPending && provider === 'password'}
									style={{ marginTop: '25px' }}
								>
									Sign Up with Email
								</Button>
								<StyledFooter className="noselect">
									<Text type="secondary">
										Sign up means you agree to Terms and Privacy Policy
									</Text>
								</StyledFooter>
							</div>
							<StyledSecondaryFooter className="noselect">
								<Text>
									Already have an account?{' '}
									<Link
										to={{
											pathname: '/signIn',
											state: props.location.state,
										}}
									>
										Sign In
									</Link>
								</Text>
							</StyledSecondaryFooter>
						</StyledForm>
					)}
				</Formik>
			</StyledContainer>
		</div>
	)
}

export default withRouter(SignUp)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 55vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
	box-shadow: ${() =>
		theme === 'dark'
			? ''
			: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'};
	background-color: ${() =>
		theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'inherent'};
`

const StyledError = styled('p')`
	color: tomato;
	font-size: 0.82em;
	margin: 0;
	width: 380px;
	max-width: 82vw;
	margin-left: auto;
	margin-right: auto;
`
const StyledDivider = styled(Divider)`
	border-top-color: ${() =>
		theme === 'dark' ? 'rgba(255,255,255, 0.22)' : 'rgba(0,0,0, 0.22)'};
`
const StyledFooter = styled('div')`
	margin-top: 2vh;
	font-size: 1em;
	max-width: 82vw;
	height: 4em;
`
const StyledSecondaryFooter = styled('div')`
	margin-top: 2vh;
	font-size: 1em;
	max-width: 82vw;
	@media (max-width: 280px) {
		font-size: 0.92em;
	}
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
