import { logger } from '@monorepo/shared-client/utils'
import {
	INTERNAL_FUNCTIONS_NAMES,
	COLLECTIONS,
	REQUEST_STATUS,
} from '@monorepo/cloud-functions/common/constants.js'
// import axios from 'axios'
import {
	firebaseAuth,
	firebase,
	firestore,
	functions,
} from '../../config/firebase'
import authActions from './redux'

// The sign-in provider can be one of the following strings:
// `custom`, `password`, `phone`, `anonymous`, `google.com`, `facebook.com`, `github.com`, `twitter.com`.

export const handleError = (error) => {
	logger.error('error:', error.message)
	throw error
}

export const getUserAccountData = async (userId) => {
	try {
		let accountData = {}
		const accountRef = firestore.collection(COLLECTIONS.accounts).doc(userId)

		const response = await accountRef.get()

		if (response.exists && response.data()) {
			accountData = response.data()
		}

		return accountData
	} catch (error) {
		logger.error('getUserAccountData error:', error)
		return null
	}
}

const updateUserAccountData = async (data = {}) => {
	try {
		const internalAPI = functions.httpsCallable('internalAPI')
		const response = await internalAPI({
			...data,
			targetFunction: INTERNAL_FUNCTIONS_NAMES.updateUserAccountData,
		})
		return response.data
	} catch (error) {
		logger.error('updateUserAccountData error:', error)
		return null
	}
}

// // ///////////////////////////////////////////////

export async function sendEmailVerification() {
	try {
		logger.log('sendEmailVerification ran...')
		const { emailVerified } = firebaseAuth.currentUser
		let response
		if (!emailVerified) {
			logger.log('sendEmailVerification actually sent email...')
			const { hostname, host } = window.location

			const actionCodeSettings = {
				url: hostname === 'localhost' ? `http://${host}` : `https://${host}`,
			}
			response = await firebaseAuth.currentUser.sendEmailVerification({
				...actionCodeSettings,
			})
		} else {
			response = true
		}
		return response
	} catch (error) {
		logger.error('sendEmailVerification error:', error)
		throw error
	}
}

export function updateUserPassword(newPassword) {
	try {
		const user = firebaseAuth.currentUser
		return user.updatePassword(newPassword)
	} catch (error) {
		logger.error('updateUserPassword error:', error)
		throw error
	}
}

export function updateUserEmail(newEmail) {
	try {
		const user = firebaseAuth.currentUser
		return user.updateEmail(newEmail)
	} catch (error) {
		logger.error('updateUserEmail error:', error)
		throw error
	}
}

export function updateUserDisplayName(displayName) {
	try {
		const user = firebaseAuth.currentUser
		return user.updateProfile({ displayName })
	} catch (error) {
		logger.error('updateUserDisplayName error:', error)
		throw error
	}
}

export function reAuthenticateUser(userCredential) {
	try {
		const user = firebaseAuth.currentUser
		return user.reauthenticateWithCredential(userCredential)
	} catch (error) {
		logger.error('reAuthenticateUser error:', error)
		throw error
	}
}

export function getUserCredential(email, password) {
	try {
		const user = firebaseAuth.currentUser
		return firebaseAuth.EmailAuthProvider.credential(user.email, password)
	} catch (error) {
		logger.error('getUserCredential error:', error)
		throw error
	}
}

export const sendPasswordResetEmail = async (data) => {
	try {
		const { email } = data
		const { hostname, host } = window.location

		const actionCodeSettings = {
			url:
				hostname === 'localhost'
					? `http://${host}/signIn`
					: `https://${host}/signIn`,
			handleCodeInApp: false,
		}
		const response = await firebaseAuth.sendPasswordResetEmail(
			email,
			actionCodeSettings
		)
		return response
	} catch (error) {
		logger.error('sendPasswordResetEmail error:', error)
		throw error
	}
}

export async function reloadUser(data) {
	try {
		const { dispatch } = data
		await firebaseAuth.currentUser.reload()
		await firebaseAuth.currentUser.getIdToken(true)
		logger.log(
			'firebaseAuth.currentUser after reload: ',
			firebaseAuth.currentUser
		)
		const user = firebaseAuth.currentUser || null
		if (user) {
			// User is signed in.
			const formattedUser = {
				id: user.uid || null,
				disabled: user.disabled || false,
				display_name: user.displayName || null,
				email: user.email || null,
				email_verified: user.emailVerified || false,
				photo_url: user.photoURL || null,
				phone_number: user.phoneNumber || null,
				providers:
					user.providerData && Array.isArray(user.providerData)
						? user.providerData.map((provider) => provider.providerId)
						: [],
			}
			dispatch(
				authActions.saveUserLocally({
					...formattedUser,
				})
			)
			const accountData = await getUserAccountData(user.uid)
			if (accountData) {
				dispatch(
					authActions.saveUserLocally({
						...accountData,
					})
				)
			}
			updateUserAccountData(formattedUser)
		} else {
			// clear user info locally
			dispatch(authActions.signOutUserLocally())
		}
		return user
	} catch (error) {
		logger.error('reloadUser error:', error)
		throw error
	}
}

// async function updateUserAccountDataInDB(userData) {
// 	// add firestore update code for user
// }

export async function deleteUser() {
	const user = firebaseAuth.currentUser
	try {
		logger.log('current user successfully deleted')
		return user.delete()
	} catch (error) {
		logger.error('error occurred deleting current user')
		logger.error('deleteUser error:', error)
		throw error
	}
}

// export async function removeAppFromUserFacebook(accessToken) {
// 	try {
// 		// const deleteResponse = await axios.delete(
// 			`https://graph.facebook.com/v2.11/me/permissions?access_token=${accessToken}`
// 		)
// 		const deleteResponseSuccess = deleteResponse.data.success
// 		logger.log(
// 			'response from removeAppFromUserFacebook deleteResponseSuccess: ',
// 			deleteResponseSuccess
// 		)
// 		return deleteResponseSuccess
// 	} catch (error) {
// 		logger.error('removeAppFromUserFacebook error:', error)
// 		throw error
// 	}
// }

function handleEmailNotVerifiedUser() {
	try {
		logger.log('handleEmailNotVerifiedUser ran...')
		sendEmailVerification()
		return null
	} catch (error) {
		logger.error('handleEmailNotVerifiedUser error:', error)
		return null
	}
}

export function authStateChangedListener({ dispatch }) {
	try {
		firebaseAuth.onAuthStateChanged(async (user) => {
			// logger.log('onAuthStateChanged ran.. with user:', user)
			if (user) {
				// User is signed in.
				const formattedUser = {
					id: user.uid || null,
					disabled: user.disabled || false,
					display_name: user.displayName || null,
					email: user.email || null,
					email_verified: user.emailVerified || false,
					photo_url: user.photoURL || null,
					phone_number: user.phoneNumber || null,
					providers:
						user.providerData && Array.isArray(user.providerData)
							? user.providerData.map((provider) => provider.providerId)
							: [],
				}
				dispatch(
					authActions.saveUserLocally({
						...formattedUser,
					})
				)

				const accountData = await getUserAccountData(user.uid)
				if (accountData) {
					dispatch(
						authActions.saveUserLocally({
							...accountData,
						})
					)
				}
				updateUserAccountData(formattedUser)
			} else {
				// clear user info locally
				dispatch(authActions.signOutUserLocally())
			}
		})
		return null
	} catch (error) {
		logger.error('authStateChangedListener error:', error)
		throw error
	}
}

export async function signInWithEmailPassword({ email, password }) {
	try {
		const { user } = await firebaseAuth.signInWithEmailAndPassword(
			email,
			password
		)
		// User is signed in.
		return user
	} catch (error) {
		switch (error.code) {
			case 'auth/user-not-found':
				error.message =
					'There is no account corresponding to this email address.'
				break
			case 'auth/invalid-email':
				error.message =
					'Email is badly formatted, Please check the email and try again.'
				break
			case 'auth/wrong-password':
				error.message =
					'Password is incorrect. Please enter a valid password for this email.'
				break
			case 'auth/user-disabled':
				error.message =
					'Our system has disabled your account for security reasons. Contact us to report this issue.'
				break
			default:
				error.message =
					'Oops, Error Occurred. Please try Again. Contact us if error persists.'
		}
		logger.error('signInWithEmailPassword error:', error)
		throw error
	}
}

export async function sendSignInLinkToEmail(data) {
	try {
		const { email } = data
		const { hostname, host } = window.location
		const actionCodeSettings = {
			url:
				hostname === 'localhost'
					? `http://${host}/signIn`
					: `https://${host}/signIn`,
			handleCodeInApp: true,
		}
		logger.log('sendSignInLinkToEmail ran')
		const response = await firebaseAuth.sendSignInLinkToEmail(
			email,
			actionCodeSettings
		)
		window.localStorage.setItem('emailForSignIn', email)
		logger.log('sendSignInLinkToEmail response: ', response)
		return response
	} catch (error) {
		logger.error('sendSignInLinkToEmail error:', error.message)
		switch (error.code) {
			case 'auth/argument-error':
				error.message =
					'handleCodeInApp is set to false. Contact us to report this issue.'
				break
			case 'auth/invalid-email':
				error.message =
					'Email is badly formatted, Please check the email and try again.'
				break
			case 'auth/missing-android-pkg-name':
				error.message =
					'Android package name missing. Contact us to report this issue.'
				break
			case 'auth/missing-continue-uri':
				error.message = 'Continue URL Missing. Contact us to report this issue.'
				break
			case 'auth/missing-ios-bundle-id':
				error.message =
					'iOS Bundle ID missing. Contact us to report this issue.'
				break
			case 'auth/invalid-continue-uri':
				error.message = 'Continue URL invalid. Contact us to report this issue.'
				break
			case 'uth/unauthorized-continue-uri':
				error.message =
					'Continue URL unauthorized. Contact us to report this issue.'
				break
			default:
				error.message =
					'Oops, Error Occurred. Please try Again. Contact us if error persists.'
		}
		logger.error('sendSignInLinkToEmail error:', error)
		throw error
	}
}

export async function isSignInWithEmailLink() {
	// Confirm the link is a sign-in with email link.
	try {
		let response = null
		if (firebaseAuth.isSignInWithEmailLink(window.location.href)) {
			logger.log('isSignInWithEmailLink is true ')
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			let email = window.localStorage.getItem('emailForSignIn')
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				email = window.prompt('Please provide your email for confirmation') // eslint-disable-line no-alert
			}
			// The client SDK will parse the code from the link for you.
			response = await firebaseAuth.signInWithEmailLink(
				email,
				window.location.href
			)
		}
		logger.log('isSignInWithEmailLink is false ')

		logger.log('signInWithEmailLink response:', response)
		return response
	} catch (error) {
		logger.error('signInWithEmailLink error:', error.message)
		switch (error.code) {
			case 'auth/invalid-action-code':
				error.message =
					'Sign in With Email code is invalid. Please request a new email link.'
				break
			case 'auth/expired-action-code':
				error.message =
					'Sign in with Email link has expired. Please request a new email link.'
				break
			case 'auth/invalid-email':
				error.message = 'Email is invalid.'
				break
			case 'auth/user-disabled':
				error.message = 'User account has been disabled.'
				break
			default:
				error.message =
					'Oops, Error Occurred. Please try Again. Contact us if error persists.'
		}
		logger.error('isSignInWithEmailLink error:', error)
		throw error
	}
}
export async function signUpWithEmailPassword(email, password) {
	try {
		const response = await firebaseAuth.createUserWithEmailAndPassword(
			email,
			password
		)
		return response
	} catch (error) {
		switch (error.code) {
			case 'auth/email-already-in-use':
				error.message =
					'Account already exists with this email address. Please sign in Instead'
				break
			case 'auth/invalid-email':
				error.message =
					'Email is badly formatted, Please check the email and try again.'
				break
			case 'auth/weak-password':
				error.message =
					'Password is weak, Please choose a stronger password and try again.'
				break
			case 'auth/operation-not-allowed':
				error.message =
					'Oops, We forgot to activate email/password Auth. Contact us to report this issue.'
				break
			default:
				error.message =
					'Oops, Error Occurred. Please try Again. Contact us if error persists.'
		}
		logger.error('signUpWithEmailPassword error:', error)
		throw error
	}
}

// // ////////////////////////////////////////////////
export async function authWithProvider(provider) {
	try {
		const response = await firebaseAuth.signInWithPopup(provider)
		return response
	} catch (error) {
		const email = error.email || ''
		logger.error('authWithProvider Error: ', error)
		switch (error.code) {
			case 'auth/account-exists-with-different-credential':
				// eslint-disable-next-line
				const signInMethods = await firebase
					.auth()
					.fetchSignInMethodsForEmail(email)
				// eslint-disable-next-line
				let existingProvider = signInMethods[0]
				switch (signInMethods[0]) {
					case 'google.com':
						existingProvider = 'GOOGLE'
						break
					case 'password':
						existingProvider = 'EMAIL/PASSWORD'
						break
					default:
						// eslint-disable-next-line
						existingProvider = signInMethods[0]
				}
				error.message = `A account already exists with email: "${email}".
				Sign-In Using: ${existingProvider}`
				break
			case 'auth/cancelled-popup-request':
				error.message =
					'Only one popup request allowed at a time. Please close the other popups, and try again.'
				break
			case 'auth/operation-not-allowed':
				error.message =
					'Auth Provider type corresponding to the credential is not Enabled.'
				break
			case 'auth/operation-not-supported-in-this-environment':
				error.message =
					'Auth Action not allowed on non Http or Https protocols.'
				break
			case 'auth/popup-blocked':
				error.message = 'Auth Popup was blocked by the browser.'
				break
			case 'auth/popup-closed-by-user':
				error.message =
					'The Auth Popup was closed without completing the Process.'
				break
			case 'auth/unauthorized-domain':
				error.message = 'The domain is not authorized for OAuth Yet.'
				break
			default:
				error.message = 'Oops, Error Occurred. Please try Again'
		}
		logger.error('authWithProvider error:', error)
		throw error
	}
}

export async function signInWithGoogle() {
	try {
		const provider = new firebase.auth.GoogleAuthProvider()
		// added scope to provider retrive user age range
		// provider.addScope('https://www.googleapis.com/auth/plus.login')
		const response = await authWithProvider(provider)
		return response
	} catch (error) {
		logger.error('signInWithGoogle error:', error)
		throw error
	}
}

export async function signInWithFacebook() {
	const provider = new firebase.auth.FacebookAuthProvider()
	const response = await authWithProvider(provider)
	return response
}

export async function signInWithTwitter() {
	try {
		const provider = new firebase.auth.TwitterAuthProvider()
		const response = await authWithProvider(provider)
		return response
	} catch (error) {
		logger.error('signInWithTwitter error:', error)
		throw error
	}
}
// // ///////////////////////////////////////////////

export async function signUpUser(data = {}) {
	try {
		logger.log('signUpUser ran with:', data)
		let response = null
		if (data.provider === 'password') {
			response = await signUpWithEmailPassword(data.email, data.password)
			logger.log('response:', response)
		} else if (data.provider === 'google') {
			response = await signInWithGoogle()
			logger.log('response:', response)
		} else if (data.provider === 'facebook') {
			response = await signInWithFacebook()
			logger.log('response:', response)
		} else if (data.provider === 'twitter') {
			response = await signInWithTwitter()
			logger.log('response:', response)
		}
		handleEmailNotVerifiedUser()
		return response
	} catch (error) {
		logger.error('signUpUser error:', error)
		throw error
	}
}

export async function signInUser(data = {}) {
	try {
		const { dispatch } = data
		let response = null
		if (data.provider === 'password') {
			response = await signInWithEmailPassword({
				email: data.email,
				password: data.password,
				dispatch,
			})
			logger.log('response:', response)
		} else if (data.provider === 'google') {
			response = await signInWithGoogle()
			logger.log('response:', response)
		} else if (data.provider === 'facebook') {
			response = await signInWithFacebook()
			logger.log('response:', response)
		} else if (data.provider === 'twitter') {
			response = await signInWithTwitter()
			logger.log('response:', response)
		}
		handleEmailNotVerifiedUser()
		const { user } = response
		const formattedUser = {
			id: user.uid || null,
			disabled: user.disabled || false,
			display_name: user.displayName || null,
			email: user.email || null,
			email_verified: user.emailVerified || false,
			photo_url: user.photoURL || null,
			phone_number: user.phoneNumber || null,
			providers:
				user.providerData && Array.isArray(user.providerData)
					? user.providerData.map((provider) => provider.providerId)
					: [],
		}
		dispatch(
			authActions.saveUserLocally({
				...formattedUser,
			})
		)
		const accountData = await getUserAccountData(user.uid)
		if (accountData) {
			dispatch(
				authActions.saveUserLocally({
					...accountData,
				})
			)
		}
		updateUserAccountData(formattedUser)
		return formattedUser
	} catch (error) {
		logger.error('signInUser error:', error)
		throw error
	}
}

export async function signOutUser({ dispatch }) {
	try {
		dispatch(authActions.signOutUserLocally())
		return firebaseAuth.signOut()
	} catch (error) {
		logger.error('signOutUser error:', error)
		throw error
	}
}

export const authWithRobinhood = async (data = {}) => {
	try {
		const internalAPI = functions.httpsCallable('internalAPI')
		const { dispatch } = data
		const response = await internalAPI({
			...data,
			targetFunction: INTERNAL_FUNCTIONS_NAMES.connectedAccountAuth,
		})
		if (response.data && response.data.status === REQUEST_STATUS.succeeded) {
			const user = firebaseAuth.currentUser || null
			if (user) {
				const accountData = await getUserAccountData(user.uid)
				if (accountData) {
					dispatch(
						authActions.saveUserLocally({
							...accountData,
						})
					)
				}
			}
		}
		return response.data
	} catch (error) {
		// console.log('createApiSecret ERROR:', error)
		logger.log('authWithRobinhood error:', error)
		throw error
	}
}
