import React from 'react'
import styled from 'styled-components'

const Loading = () => {
	return <StyledContainer>...loading</StyledContainer>
}
const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`

export default Loading
