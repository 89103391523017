import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
	Typography,
	Divider,
	Button,
	message,
	PageHeader,
	//  Spin
} from 'antd'
import styled from 'styled-components'
import { AiOutlineMail } from 'react-icons/ai'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
import {
	// reloadUser,
	sendEmailVerification,
} from '../modules/auth/api'
import { appGlobalTheme as theme } from '../app.json'
import { ReactComponent as Logo } from '../assets/images/logo.svg'

const { Title, Text } = Typography

const EmailVerificationRequired = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)

	/// //////////////////////////////////////////
	/// / Local States
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const signOut = () => {
		dispatch(authActions.signOutUserRequestStart({ dispatch }))
		props.history.push('/')
	}

	// const emailVerifiedContinue = async () => {
	// 	logger.log('USER HIT Continue')
	// 	const { history } = props
	// 	const { emailVerified } = await reloadUser({ dispatch })
	// 	if (!emailVerified) {
	// 		message.error(
	// 			'Email not yet verified. Please check your inbox or request a new verification link.',
	// 			8
	// 		)
	// 	} else {
	// 		history.replace(`/signedInHome`)
	// 	}
	// }

	const handleSendingEmailVerification = async () => {
		try {
			const response = await sendEmailVerification()
			message.success('Verification email sent.', 5)
			return response
		} catch (error) {
			message.error('Error Occurred sending verification email.', 5)
			return null
		}
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (!user) {
			props.history.push({
				pathname: '/signedOutHome',
				state: props.location.state,
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
		return () => {
			dispatch(authActions.clearAllAuthErrorsLocally())
		}
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Verify Your Email
				</Title>
				<StyledContentContainer>
					<div>
						<AiOutlineMail
							style={{
								fontSize: '6em',
								color: '#52c41a',
								marginBottom: '2vh',
							}}
						/>
					</div>
					<div>
						<Text>
							We sent a verification email to{' '}
							<strong>{user && user.email ? user.email : ''}</strong>.
						</Text>
					</div>
					<div style={{ marginTop: '1vh' }}>
						<Text>Click the link inside to get started!</Text>
					</div>
					<div>
						<StyledDivider />
					</div>
					<div>
						<Button
							type="ghost"
							style={{ width: 320, maxWidth: '80%', marginTop: '2vh' }}
							onClick={handleSendingEmailVerification}
						>
							Email didn&apos;t arrive?
						</Button>
					</div>
					<div>
						<Button
							type="link"
							style={{ width: 320, maxWidth: '80%', marginTop: '2vh' }}
							onClick={signOut}
						>
							Sign out
						</Button>
					</div>
				</StyledContentContainer>
			</StyledContainer>
		</div>
	)
}

export default withRouter(EmailVerificationRequired)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`
const StyledContentContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2rem;
	width: 420px;
	max-width: 75vw;
	box-shadow: ${() =>
		theme === 'dark'
			? ''
			: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'};
	background-color: ${() =>
		theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'inherent'};
`
const StyledDivider = styled(Divider)`
	border-top-color: ${() =>
		theme === 'dark' ? 'rgba(255,255,255, 0.22)' : 'rgba(0,0,0, 0.22)'};
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
