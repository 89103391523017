import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import {
	offlineMiddleware,
	suspendSaga,
	consumeActionMiddleware,
} from 'redux-offline-queue'
import startupActions from '../modules/startup/redux'

export default (rootReducer, rootSaga) => {
	/* ------------- Redux Configuration ------------- */

	const middleware = []
	const enhancers = []

	/* ------------- redux-offline-queue offline Middleware ------------- */

	middleware.push(offlineMiddleware({ stateName: 'offline' }))

	/* ------------- Saga Middleware ------------- */

	const sagaMiddleware = createSagaMiddleware()
	const suspendSagaMiddleware = suspendSaga(sagaMiddleware)

	middleware.push(suspendSagaMiddleware)

	/** The consume middleware should be placed last.
	 * We allow the previous middlewares (especially the saga middleware) to react to the action
	 * before it is eventually consumed.
	 */

	/* ------------- redux-offline-queue  consume Middleware ------------- */

	middleware.push(consumeActionMiddleware())

	/* ------------- Assemble Middleware ------------- */

	enhancers.push(applyMiddleware(...middleware))

	// if in development enable redux-devtools
	const composeEnhancers =
		global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		process.env.NODE_ENV === 'development'
			? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
			: compose

	const store = createStore(rootReducer, composeEnhancers(...enhancers))

	const startup = () => store.dispatch(startupActions.startup())

	const persistor = persistStore(store, null, startup)
	// kick off root saga
	const sagasManager = sagaMiddleware.run(rootSaga)

	return {
		store,
		persistor,
		sagasManager,
		sagaMiddleware,
	}
}
