import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

// isAuthenticated is passed as prop here
const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
	// const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States
	/// //////////////////////////////////////////

	/// / Refs
	/// //////////////////////////////////////////
	/// / UseEffect Calls

	return (
		<Route
			{...rest}
			render={(props) => {
				if (user && user.email_verified) {
					return <Component {...props} />
					// eslint-disable-next-line no-else-return
				} else if (user) {
					return (
						<Redirect
							to={{
								pathname: '/emailVerificationRequired',
								state: { from: props.location },
							}}
						/>
					)
				} else {
					return (
						<Redirect
							to={{
								pathname: '/',
								state: { from: props.location },
							}}
						/>
					)
				}
			}}
		/>
	)
}

export default withRouter(ProtectedRoute)
