import storage from 'redux-persist/lib/storage'
import { createMigrate } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import migrations from '../store/migrations'
import { name as appName } from '../app.json'

// createFilter: for persisting subset of reducer : ignoring all other reducer keys
// createBlacklistFilter: for blacklisting subset of reducer : persisting all other reducer keys

// const saveSubsetFilter = createBlacklistFilter('reducerA', [
//   'reducerA_key'
// ])

// const saveSubsetFilterB = createFilter('otherReducer', [
//   'otherReducer_key'
// ])

// then use like so in config below:
// transforms: [saveSubsetFilter, saveSubsetFilterB]

// persists only the user key in the auth reducer
const saveSubsetFilterAuth = createFilter('auth', ['user'])

const REDUX_PERSIST = {
	active: true,
	version: 0, // update this on each new added migration
	key: `${appName}-primary`,
	storage,
	stateReconciler: autoMergeLevel2,
	// Reducer keys that you do NOT want stored to persistence here.
	// blacklist: ['navigation'],
	// Optionally, just specify the keys you DO want stored to persistence.
	// An empty array means 'don't store any reducers'
	whitelist: ['auth'],
	transforms: [saveSubsetFilterAuth],
	migrate: createMigrate(migrations, { debug: true }),
}

export default REDUX_PERSIST
