import { createActions, createReducer } from 'reduxsauce'
import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	retrieveShowerThoughtRequestStart: null,
	retrieveShowerThoughtPending: null,
	retrieveShowerThoughtFulfilled: ['showerThought'],
	retrieveShowerThoughtRejected: ['error'],
})

// THe following makes showerThoughtRequest a action that will be saved to offline queue if network is offline
// and ran once network connection is restored
markActionsOffline(Creators, ['retrieveShowerThoughtRequestStart'])

export const redditTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
	showerThought: null,
	retrieveShowerThoughtPending: false,
	retrieveShowerThoughtError: null,
}

export const retrieveShowerThoughtPending = (state = INITIAL_STATE) => {
	return produce(state, (draft) => {
		draft.retrieveShowerThoughtPending = true // eslint-disable-line no-param-reassign
		draft.retrieveShowerThoughtError = null // eslint-disable-line no-param-reassign
	})
}

export const retrieveShowerThoughtFulfilled = (
	state = INITIAL_STATE,
	action
) => {
	return produce(state, (draft) => {
		draft.retrieveShowerThoughtPending = false
		draft.showerThought = action.showerThought
		draft.retrieveShowerThoughtError = null
	})
}

export const retrieveShowerThoughtRejected = (
	state = INITIAL_STATE,
	action
) => {
	return produce(state, (draft) => {
		draft.retrieveShowerThoughtPending = false
		draft.showerThought = null
		draft.retrieveShowerThoughtError = action.error
	})
}

// map our action types to our reducer functions
export const HANDLERS = {
	[Types.RETRIEVE_SHOWER_THOUGHT_PENDING]: retrieveShowerThoughtPending,
	[Types.RETRIEVE_SHOWER_THOUGHT_FULFILLED]: retrieveShowerThoughtFulfilled,
	[Types.RETRIEVE_SHOWER_THOUGHT_REJECTED]: retrieveShowerThoughtRejected,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
