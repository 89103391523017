import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Modal, Button, message, Spin, Typography } from 'antd'
import { Form, FormItem, Input } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import flatten from 'flat'
import {
	AiOutlineUser,
	AiOutlineLock,
	AiFillEye,
	AiFillEyeInvisible,
} from 'react-icons/ai'
import JSEncrypt from 'jsencrypt'
import {
	CONNECTED_ACCOUNT_SOURCES,
	REQUEST_STATUS,
} from '@monorepo/cloud-functions/common/constants.js'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
// import { appGlobalTheme as theme } from '../app.json'
import RobinhoodLogo from '../assets/images/connected-accounts/robinhood-word-logo.svg'

const { Text } = Typography

const encryptConnectedAccountAuthData = ({
	username,
	password,
	source,
	mfa_code,
}) => {
	const connectedAccountAuthData = {
		username,
		password,
		source,
		mfa_code,
	}
	const jsEncrypt = new JSEncrypt()
	jsEncrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY)
	return jsEncrypt.encrypt(JSON.stringify(connectedAccountAuthData))
}

const RobinHoodAuth = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const {
		// user,
		authWithRobinhoodStatus,
		authWithRobinhoodPending,
		authWithRobinhoodError,
	} = useSelector((state) => state.auth)

	/// //////////////////////////////////////////
	/// / Local States
	const [modalVisible, setModalVisible] = useState(true)
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers

	const FormikPatchTouched = () => {
		const {
			errors,
			setFieldTouched,
			isSubmitting,
			isValidating,
		} = useFormikContext()
		useEffect(() => {
			if (isSubmitting && !isValidating) {
				// eslint-disable-next-line
				for (const path of Object.keys(flatten(errors))) {
					setFieldTouched(path, true, false)
				}
			}
		}, [errors, isSubmitting, isValidating, setFieldTouched])
		return null
	}

	// const handleLinking = () => {
	// 	// logger.log('handleLinking clicked')
	// 	setModalVisible(true)
	// }

	const validateNotEmpty = (value) => {
		let error
		if (!value) {
			error = 'required!'
		} else if (value.length === 0) {
			error = 'required!'
		}
		return error
	}

	const onFinish = (values) => {
		dispatch(
			authActions.authWithRobinhoodRequestStart({
				data: {
					connectedAccountAuthData: encryptConnectedAccountAuthData({
						username: values.username,
						password: values.password,
						mfa_code: values.mfa_code || null,
						source: CONNECTED_ACCOUNT_SOURCES.robinhood,
					}),
					dispatch,
				},
			})
		)
	}

	const handleModalCancel = () => {
		setModalVisible(false)
		dispatch(authActions.clearAuthWithRobinhoodStatusLocally())
		dispatch(authActions.clearAllAuthErrorsLocally())
		if (props.onCancel) {
			props.onCancel()
		}
	}

	/// //////////////////////////////////////////
	/// / UseEffect Calls

	useEffect(() => {
		if (
			authWithRobinhoodStatus &&
			authWithRobinhoodStatus.status === REQUEST_STATUS.succeeded
		) {
			message.success('Robinhood successfully linked!', 8)
			dispatch(authActions.clearAuthWithRobinhoodStatusLocally())
			handleModalCancel()
		}
		return () => {
			dispatch(authActions.clearAllAuthErrorsLocally())
		}
	}, [authWithRobinhoodStatus]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<StyledContainer>
			<Modal
				className="connected-account"
				title={
					<div style={{ textAlign: 'center' }}>
						<Text>Provide Robinhood Credentials</Text>
					</div>
				}
				visible={modalVisible}
				onCancel={handleModalCancel}
				footer={null}
				closable={false}
				destroyOnClose
				maskClosable={false}
				keyboard={false}
			>
				<div style={{ textAlign: 'center' }}>
					<img alt="robinhood" src={RobinhoodLogo} height="60px" />
				</div>
				<Formik
					enableReinitialize
					initialValues={{
						username: '',
						password: '',
						mfa_code: null,
					}}
					onSubmit={onFinish}
				>
					{(formProps) => (
						<StyledForm autoComplete="off" layout="vertical">
							{authWithRobinhoodPending && (
								<div style={{ marginTop: '2vh' }}>
									<Spin size="large" />
									<div style={{ textAlign: 'center', marginTop: '2vh' }}>
										<div>Communicating with Robinhood...</div>
									</div>
								</div>
							)}
							<FormikPatchTouched />
							{!authWithRobinhoodPending && (
								<div>
									<FormItem
										name="username"
										label={
											window.screen.width >= 768 ? (
												<span className="noselect">Email/Username</span>
											) : null
										}
										labelalign="left"
										style={{
											display:
												authWithRobinhoodStatus &&
												authWithRobinhoodStatus.mfa_required
													? 'none'
													: 'inherent',
										}}
										required
										validate={validateNotEmpty}
									>
										<Input
											name="username"
											placeholder="Robinhood Email/Username"
											autoComplete="off"
											size="large"
											prefix={<AiOutlineUser />}
											style={{
												width: '420px',
												maxWidth: '75vw',
												display:
													authWithRobinhoodStatus &&
													authWithRobinhoodStatus.mfa_required
														? 'none'
														: 'inherent',
											}}
										/>
									</FormItem>
									<FormItem
										name="password"
										label={
											window.screen.width >= 768 ? (
												<div
													className="noselect"
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<span>Password&nbsp;</span>
												</div>
											) : null
										}
										labelalign="left"
										required
										style={{
											display:
												authWithRobinhoodStatus &&
												authWithRobinhoodStatus.mfa_required
													? 'none'
													: 'inherent',
										}}
										validate={validateNotEmpty}
									>
										<Input.Password
											name="password"
											placeholder="Robinhood Password"
											autoComplete="off"
											size="large"
											prefix={<AiOutlineLock />}
											style={{
												width: '420px',
												maxWidth: '75vw',
												display:
													authWithRobinhoodStatus &&
													authWithRobinhoodStatus.mfa_required
														? 'none'
														: 'inherent',
											}}
											iconRender={(visible) =>
												visible ? <AiFillEye /> : <AiFillEyeInvisible />
											}
										/>
									</FormItem>
									{authWithRobinhoodStatus &&
										authWithRobinhoodStatus.mfa_required && (
											<FormItem
												name="mfa_code"
												label={
													window.screen.width >= 768 ? (
														<span className="noselect">MFA Code</span>
													) : null
												}
												labelalign="left"
												style={{
													display:
														authWithRobinhoodStatus &&
														authWithRobinhoodStatus.mfa_required
															? 'inherent'
															: 'none',
												}}
												required
												validate={
													authWithRobinhoodStatus &&
													authWithRobinhoodStatus.mfa_required
														? validateNotEmpty
														: null
												}
											>
												<Input
													name="mfa_code"
													autoComplete="robinhood_mfa_code"
													placeholder={`Enter MFA Code ${
														// eslint-disable-next-line no-nested-ternary
														authWithRobinhoodStatus.mfa_type === 'sms'
															? `Sent Via SMS`
															: authWithRobinhoodStatus.mfa_type === 'email'
															? `Sent Via Email`
															: ''
													}`}
													autoFocus
													size="large"
													style={{
														width: '420px',
														maxWidth: '75vw',
														display:
															authWithRobinhoodStatus &&
															authWithRobinhoodStatus.mfa_required
																? 'inherent'
																: 'none',
													}}
												/>
											</FormItem>
										)}

									<StyledError>{authWithRobinhoodError || ''}</StyledError>
									<div>
										<Button
											type="danger"
											size="large"
											style={{
												marginTop: '25px',
												marginRight: '10px',
												width: '160px',
												maxWidth: '35vw',
											}}
											onClick={handleModalCancel}
										>
											Cancel
										</Button>
										<Button
											type="primary"
											size="large"
											htmlType="submit"
											loading={authWithRobinhoodPending}
											style={{
												marginTop: '25px',
												width: '160px',
												maxWidth: '35vw',
											}}
										>
											Connect
										</Button>
									</div>
									{authWithRobinhoodStatus &&
										authWithRobinhoodStatus.mfa_required && (
											<Button
												type="link"
												size="large"
												style={{
													marginTop: '35px',
													width: '160px',
													maxWidth: '35vw',
												}}
												onClick={() => {
													const { values } = formProps
													values.mfa_code = null
													message.success('New Code Request Sent!', 5)
													onFinish(values)
												}}
											>
												Request New Code
											</Button>
										)}
								</div>
							)}
						</StyledForm>
					)}
				</Formik>
			</Modal>
		</StyledContainer>
	)
}

export default withRouter(RobinHoodAuth)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`
const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	padding: 2em;
`

const StyledError = styled('p')`
	color: tomato;
	font-size: 0.82em;
	margin: 0;
	width: 380px;
	max-width: 82vw;
	margin-left: auto;
	margin-right: auto;
`
