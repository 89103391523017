/* eslint-disable */
window.addEventListener('load', function () {
	var deferredPrompt
	// delay webapp install banner by listening to the prompt event and saving it to display later on user button click
	window.addEventListener('beforeinstallprompt', function (event) {
		event.preventDefault()
		// Stash the event so it can be triggered later.

		if (!window.matchMedia('(display-mode: standalone)').matches) {
			// Enable the button.
			// Stash the event so it can be triggered later.
			deferredPrompt = event
			showPrompt()
			return false
		}
	})

	function showPrompt() {
		if (document.getElementById('webAppInstall')) {
			// do stuff
			var webAppInstall = document.getElementById('webAppInstall')
			if (deferredPrompt !== undefined) {
				webAppInstall.style.display = 'inline'
			}
			webAppInstall.addEventListener('click', function () {
				if (deferredPrompt !== undefined) {
					// The user has had a positive interaction with our app and Chrome
					// has tried to prompt previously, so let's show the prompt.
					deferredPrompt.prompt()

					// Follow what the user has selected.
					deferredPrompt.userChoice.then(function (choiceResult) {
						console.log(choiceResult.outcome)

						if (choiceResult.outcome === 'dismissed') {
							console.log('User cancelled home screen install')
						} else {
							console.log('User added to home screen')
						}
						webAppInstall.style.display = 'none'
						// We no longer need the prompt.  Clear it up.
						deferredPrompt = null
					})
				}
			})
		} else {
			setTimeout(showPrompt, 1200)
		}
	}
})
