const logger = {
	assert: (prefix = '', message = '') =>
		process.env.NODE_ENV === 'development'
			? console.assert(prefix, message)
			: null,
	clear: () =>
		process.env.NODE_ENV === 'development' ? console.clear() : null,
	count: () =>
		process.env.NODE_ENV === 'development' ? console.count() : null,
	countReset: () =>
		process.env.NODE_ENV === 'development' ? console.countReset() : null,
	debug: (prefix = '', message) =>
		process.env.NODE_ENV === 'development'
			? console.debug(prefix, message)
			: null,
	error: (prefix = '', message = '') =>
		process.env.NODE_ENV === 'development'
			? console.error(prefix, message)
			: null,
	info: (prefix = '', message = '') =>
		process.env.NODE_ENV === 'development'
			? console.info(prefix, message)
			: null,
	log: (prefix = '', message = '') =>
		process.env.NODE_ENV === 'development'
			? console.log(prefix, message)
			: null,
	time: (label) =>
		process.env.NODE_ENV === 'development' ? console.time(label) : null,
	timeEnd: (label) =>
		process.env.NODE_ENV === 'development' ? console.timeEnd(label) : null,
	timeLog: (label) =>
		process.env.NODE_ENV === 'development' ? console.timeLog(label) : null,
	warn: (prefix = '', message = '') =>
		process.env.NODE_ENV === 'development'
			? console.warn(prefix, message)
			: null,
}

export default logger
