import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import {
	Typography,
	Divider,
	Button,
	message,
	PageHeader,
	//  Spin
} from 'antd'
import { Form, FormItem, Input } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import flatten from 'flat'
import {
	AiOutlineUser,
	AiOutlineLock,
	AiFillEye,
	AiFillEyeInvisible,
} from 'react-icons/ai'
import { FcGoogle } from 'react-icons/fc'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
import { appGlobalTheme as theme } from '../app.json'
import { ReactComponent as Logo } from '../assets/images/logo.svg'

const { Title, Text } = Typography

const SignIn = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const {
		user,
		// signInUserStatus,
		sendSignInLinkToEmailStatus,
		// sendSignInLinkToEmailPending,
		sendSignInLinkToEmailError,
		signInUserPending,
		signInUserError,
		// isSignInWithEmailLinkPending,
		isSignInWithEmailLinkError,
	} = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States
	const [provider, setProvider] = useState(null)

	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers

	const onFinish = (values) => {
		// logger.log('values:', values)
		dispatch(
			authActions.signInUserRequestStart({
				data: {
					email: values.email,
					password: values.password,
					provider: 'password',
					dispatch,
				},
			})
		)
		setProvider('password')
	}

	const signInUserWithGoogle = () => {
		dispatch(
			authActions.signInUserRequestStart({
				data: {
					provider: 'google',
					dispatch,
				},
			})
		)
		setProvider('google')
	}

	const FormikPatchTouched = () => {
		const {
			errors,
			setFieldTouched,
			isSubmitting,
			isValidating,
		} = useFormikContext()
		useEffect(() => {
			if (isSubmitting && !isValidating) {
				// eslint-disable-next-line
				for (const path of Object.keys(flatten(errors))) {
					setFieldTouched(path, true, false)
				}
			}
		}, [errors, isSubmitting, isValidating, setFieldTouched])
		return null
	}

	const validateEmail = (value) => {
		let error
		if (!value) {
			error = 'Email is required!'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Invalid email address'
		}
		return error
	}

	const validateNotEmpty = (value) => {
		let error
		if (!value) {
			error = 'required!'
		} else if (value.length === 0) {
			error = 'required!'
		}
		return error
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (user) {
			props.history.push({
				pathname: '/emailVerificationRequired',
				state: props.location.state,
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
		return () => {
			dispatch(authActions.clearAllAuthErrorsLocally())
		}
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (sendSignInLinkToEmailStatus) {
			message.success(
				'Email link was sent! Please use the link to sign in.',
				12
			)
		}
	}, [sendSignInLinkToEmailStatus]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Welcome
				</Title>
				<Formik
					initialValues={{
						email: '',
					}}
					onSubmit={onFinish}
				>
					{() => (
						<StyledForm layout="vertical">
							<FormikPatchTouched />
							<div>
								<Button
									size="large"
									type="primary"
									block
									style={{
										marginTop: '2vh',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										verticalAlign: 'center',
									}}
									onClick={signInUserWithGoogle}
								>
									<FcGoogle
										style={{
											fontSize: '1.8em',
											marginRight: '1vw',
											backgroundColor: '#ffffff',
											padding: '0.1em',
										}}
									/>
									<span>Continue With Google</span>
								</Button>
								<StyledDivider className="noselect" orientation="center">
									<Text type="secondary">OR</Text>
								</StyledDivider>
								<FormItem
									name="email"
									label={
										window.screen.width >= 768 ? (
											<span className="noselect">Email</span>
										) : null
									}
									labelalign="left"
									required
									validate={validateEmail}
								>
									<Input
										name="email"
										placeholder="Email"
										disabled={signInUserPending && provider === 'password'}
										size="large"
										prefix={<AiOutlineUser />}
										style={{ width: '420px', maxWidth: '75vw' }}
									/>
								</FormItem>
								<FormItem
									name="password"
									label={
										window.screen.width >= 768 ? (
											<div
												className="noselect"
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<span>Password&nbsp;</span>
											</div>
										) : null
									}
									labelalign="left"
									required
									validate={validateNotEmpty}
								>
									<Input.Password
										name="password"
										placeholder="Password"
										disabled={signInUserPending && provider === 'password'}
										size="large"
										prefix={<AiOutlineLock />}
										style={{
											width: '420px',
											maxWidth: '75vw',
										}}
										iconRender={(visible) =>
											visible ? <AiFillEye /> : <AiFillEyeInvisible />
										}
									/>
								</FormItem>
								<StyledError>
									{signInUserError ||
										isSignInWithEmailLinkError ||
										sendSignInLinkToEmailError ||
										''}
								</StyledError>
								<Button
									type="primary"
									size="large"
									block
									htmlType="submit"
									loading={signInUserPending && provider === 'password'}
									style={{ marginTop: '25px' }}
								>
									Continue With Email
								</Button>
								<StyledFooter className="noselect">
									<Text type="secondary">
										<Link
											to={{
												pathname: '/forgotPassword',
												state: props.location.state,
											}}
										>
											Forgot password?
										</Link>
									</Text>
								</StyledFooter>
							</div>
							<StyledSecondaryFooter className="noselect">
								<Text>
									Don&apos;t have an account?{' '}
									<Link
										to={{
											pathname: '/signUp',
											state: props.location.state,
										}}
									>
										Sign Up
									</Link>
								</Text>
							</StyledSecondaryFooter>
						</StyledForm>
					)}
				</Formik>
			</StyledContainer>
		</div>
	)
}

export default withRouter(SignIn)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
	box-shadow: ${() =>
		theme === 'dark'
			? ''
			: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'};
	background-color: ${() =>
		theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'inherent'};
`

const StyledError = styled('p')`
	color: tomato;
	font-size: 0.82em;
	margin: 0;
	width: 380px;
	max-width: 82vw;
	margin-left: auto;
	margin-right: auto;
`

const StyledDivider = styled(Divider)`
	border-top-color: ${() =>
		theme === 'dark' ? 'rgba(255,255,255, 0.22)' : 'rgba(0,0,0, 0.22)'};
`

const StyledFooter = styled('div')`
	margin-top: 2vh;
	font-size: 1em;
	max-width: 82vw;
	height: 4em;
`
const StyledSecondaryFooter = styled('div')`
	margin-top: 2vh;
	font-size: 1em;
	max-width: 82vw;
	@media (max-width: 280px) {
		font-size: 0.92em;
	}
`
// const StyledOTPVerifyPendingContainer = styled('div')`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	text-align: center;
// 	flex-direction: column;
// 	margin-left: auto;
// 	margin-right: auto;
// 	height: 120px;
// 	max-height: 28vh;
// `
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
