import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Popconfirm, Typography } from 'antd'
import { CONNECTED_ACCOUNT_SOURCES } from '@monorepo/cloud-functions/common/constants.js'
// import { logger } from '@monorepo/shared-client/utils'
import RobinhoodLogo from '../assets/images/connected-accounts/robinhood-word-logo.svg'
import authActions from '../modules/auth/redux'
import RobinHoodAuth from './RobinHoodAuth'

const { Text, Title } = Typography
const now = Date.now()

const ConnectedAccounts = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)
	const { connected_accounts = {} } = user
	/// //////////////////////////////////////////
	/// / Local States
	const [robinHoodAuthModal, setRobinHoodAuthModal] = useState(false)
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const signOut = () => {
		dispatch(authActions.signOutUserRequestStart({ dispatch }))
		props.history.push('/')
	}
	const handleRobinHoodLinking = () => {
		setRobinHoodAuthModal(true)
	}
	const handleAuthModalCancel = () => {
		setRobinHoodAuthModal(false)
	}

	/// //////////////////////////////////////////
	/// / UseEffect Calls

	return (
		<StyledContainer>
			<Title
				className="noselect"
				level={2}
				style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
			>
				Connected Accounts
			</Title>
			{robinHoodAuthModal && <RobinHoodAuth onCancel={handleAuthModalCancel} />}
			<div style={{ marginTop: '5vh' }}>
				{connected_accounts && Object.values(connected_accounts).length > 0 ? (
					Object.values(connected_accounts).map((connected_account) => {
						return (
							<div key={connected_account.id}>
								{connected_account.source ===
								CONNECTED_ACCOUNT_SOURCES.robinhood ? (
									<Popconfirm
										title={
											<div>
												<div>Want to Update Robinhood Connection?</div>
											</div>
										}
										onConfirm={handleRobinHoodLinking}
										okText="Yes"
										cancelText="Cancel"
									>
										<Button
											type="ghost"
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												verticalAlign: 'center',
												paddingLeft: '2em',
												paddingTop: '1em',
												paddingBottom: '2em',
												width: '240px',
												maxWidth: '92vw',
											}}
										>
											<img alt="robinhood" src={RobinhoodLogo} height="80px" />
										</Button>
									</Popconfirm>
								) : null}
								<div style={{ marginTop: '2vh' }}>
									Connection Status:{' '}
									{connected_account.auth_expires_at &&
									connected_account.auth_expires_at.seconds &&
									connected_account.auth_expires_at.seconds * 1000 > now ? (
										<Text type="success">ACTIVE</Text>
									) : (
										<Text type="danger">EXPIRED</Text>
									)}
								</div>
							</div>
						)
					})
				) : (
					<div>No connected Accounts</div>
				)}
			</div>

			<Button onClick={signOut} style={{ position: 'absolute', bottom: '2vh' }}>
				Sign out
			</Button>
		</StyledContainer>
	)
}

export default withRouter(ConnectedAccounts)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`
