import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'antd'
import RobinhoodLogo from '../assets/images/connected-accounts/robinhood-word-logo.svg'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
import RobinHoodAuth from './RobinHoodAuth'

const HomeScreen = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)
	/// //////////////////////////////////////////
	/// / Local States
	const [robinHoodAuthModal, setRobinHoodAuthModal] = useState(false)
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const signOut = () => {
		dispatch(authActions.signOutUserRequestStart({ dispatch }))
		props.history.push('/')
	}

	const handleRobinHoodLinking = () => {
		setRobinHoodAuthModal(true)
	}
	const handleAuthModalCancel = () => {
		setRobinHoodAuthModal(false)
	}

	/// //////////////////////////////////////////
	/// / UseEffect Calls

	return (
		<StyledContainer>
			<Button
				id="webAppInstall"
				style={{
					position: 'absolute',
					top: '2vh',
					right: '2vw',
					display: 'none',
				}}
			>
				Install Web App
			</Button>
			<h1>Home Screen</h1>
			{user &&
				user.created_at &&
				(!user.connected_accounts ||
					(user.connected_accounts &&
						Object.values(user.connected_accounts).length === 0)) && (
					<Button
						type="ghost"
						onClick={handleRobinHoodLinking}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							verticalAlign: 'center',
							paddingLeft: '2em',
							paddingTop: '1em',
							paddingBottom: '2em',
							width: '240px',
							maxWidth: '92vw',
						}}
					>
						<img alt="robinhood" src={RobinhoodLogo} height="80px" />
					</Button>
				)}
			{robinHoodAuthModal && <RobinHoodAuth onCancel={handleAuthModalCancel} />}
			<Button onClick={signOut} style={{ position: 'absolute', bottom: '2vh' }}>
				Sign out
			</Button>
		</StyledContainer>
	)
}

export default withRouter(HomeScreen)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`
