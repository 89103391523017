import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
// import { logger } from '@monorepo/shared-client/utils'
import RecoverEmail from './authActionHandlers/RecoverEmail'
import ResetPassword from './authActionHandlers/ResetPassword'
import VerifyEmail from './authActionHandlers/VerifyEmail'
import NoMatch from './NoMatch'

// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience
// continueUrl  - url to return the user back to

const AuthAction = (props) => {
	// Get the action to complete.
	const parsed = queryString.parse(props.location.search, { decode: false })
	const { mode, oobCode: actionCode, continueUrl } = parsed
	// Handle the user management action.

	const renderAuthActionComponent = () => {
		switch (mode) {
			case 'recoverEmail':
				// Display reset password handler and UI.
				return <RecoverEmail actionCode={actionCode} />
			case 'resetPassword':
				// Display email recovery handler and UI.
				return (
					<ResetPassword actionCode={actionCode} continueUrl={continueUrl} />
				)
			case 'verifyEmail':
				// Display email verification handler and UI.
				return <VerifyEmail actionCode={actionCode} continueUrl={continueUrl} />
			default:
				// Error: invalid mode.
				return <NoMatch />
		}
	}

	// useEffect(() => {
	// 	const parsed = queryString.parse(props.location.search, { decode: false })
	// 	const { mode, oobCode, continueUrl } = parsed

	// }, []) // eslint-disable-line react-hooks/exhaustive-deps

	return renderAuthActionComponent()
}

export default withRouter(AuthAction)
