import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { all } from 'redux-saga/effects'
import configureStore from './configureStore'
import ReduxPersist from '../config/reduxPersist'
import { authTypes } from '../modules/auth/redux'

/* ------------- Assemble The Sagas ------------- */

import startupSagas from '../modules/startup/sagas'
import authSagas from '../modules/auth/sagas'
import redditSagas from '../modules/reddit/sagas'

/* ------------- Combine All Sagas in Single RootSaga ------------- */

function* rootSaga() {
	yield all([...startupSagas, ...authSagas, ...redditSagas])
}

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
	network: require('../modules/network/redux').reducer,
	offline: require('redux-offline-queue').reducer,
	auth: require('../modules/auth/redux').reducer,
	reddit: require('../modules/reddit/redux').reducer,
})

// this function(reducer) run at all action calls and
// checks if action type is SIGN_OUT_USER_FULFILLED if so it sets state of all reducers to undefined, thus that sets them back to initial defined state
function rootReducer(state, action) {
	if (action.type === authTypes.SIGN_OUT_USER_FULFILLED) {
		// eslint-disable-next-line
    state = undefined
	}
	// if the action is not SIGN_OUT_USER_FULFILLED nothing happens the reducers just gets passed through to creating a store
	return reducers(state, action)
}

export default () => {
	let finalReducers = rootReducer
	// If rehydration is on use persistReducer otherwise default combineReducers
	if (ReduxPersist.active) {
		const persistConfig = ReduxPersist
		finalReducers = persistReducer(persistConfig, rootReducer)
	}

	// eslint-disable-next-line
  let { store, persistor, sagasManager, sagaMiddleware } = configureStore(
		finalReducers,
		rootSaga
	)
	if (module.hot) {
		module.hot.accept(async () => {
			let nextRootReducer = rootReducer
			if (ReduxPersist.active) {
				const persistConfig = ReduxPersist
				nextRootReducer = persistReducer(persistConfig, rootReducer)
			}

			store.replaceReducer(nextRootReducer)

			const newYieldedSagas = rootSaga
			sagasManager.cancel()
			await sagasManager.done
			sagasManager = sagaMiddleware.run(newYieldedSagas)
		})
	}

	return {
		store,
		persistor,
	}
}
