import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	startup: null,
})

export const startupTypes = Types
export default Creators
