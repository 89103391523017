import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, message, PageHeader, Spin, Typography } from 'antd'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
// import { logger } from '@monorepo/shared-client/utils'
import { reloadUser, sendEmailVerification } from '../../modules/auth/api'
import { firebaseAuth } from '../../config/firebase'
// import { appGlobalTheme as theme } from '../../app.json'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'

const { Title } = Typography

const VerifyEmail = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const { user } = useSelector((state) => state.auth)

	/// //////////////////////////////////////////
	/// / Local States
	const [actionCodeCheckStatus, setActionCodeCheckStatus] = useState(null)
	const [actionCodeCheckPending, setActionCodeCheckPending] = useState(true)

	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers
	const emailVerifiedContinue = async () => {
		const { history, continueUrl } = props
		if (user) {
			const { emailVerified } = await reloadUser({ dispatch })
			if (!emailVerified) {
				message.error(
					'Email not yet verified. Please check your inbox or request a new verification link.',
					8
				)
			} else {
				history.replace(`/signedInHome`)
			}
		} else if (continueUrl) {
			window.location.replace(continueUrl)
		}
	}

	const handleRouteToSignIn = () => {
		const { history } = props
		history.replace(`/signIn`)
	}

	const handleSendingEmailVerification = async () => {
		const { history } = props
		await sendEmailVerification()
		history.replace(`/emailVerificationRequired`)
	}

	const checkActionCode = async () => {
		try {
			// Try to apply the email verification code.
			await firebaseAuth.applyActionCode(props.actionCode)
			setActionCodeCheckStatus(true)
			setActionCodeCheckPending(false)
		} catch (error) {
			// Code is invalid or expired.
			setActionCodeCheckStatus(null)
			setActionCodeCheckPending(false)
		}
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		checkActionCode()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Email Verification
				</Title>
				{actionCodeCheckPending && (
					<StyledPendingContainer>
						<Spin size="large" />
						<div style={{ marginTop: '2vh' }}>verifying link...</div>
					</StyledPendingContainer>
				)}
				{!actionCodeCheckPending && (
					<StyledContentContainer>
						{!actionCodeCheckStatus && (
							<div>
								<h3 style={{ color: 'tomato' }}>
									Verification Code is invalid or has expired.
								</h3>
								{user ? (
									<Button basic onClick={handleSendingEmailVerification}>
										Request New Verification Email
									</Button>
								) : (
									<Button basic onClick={handleRouteToSignIn}>
										Go Back Home
									</Button>
								)}
							</div>
						)}
						{actionCodeCheckStatus && (
							<div>
								<div>
									<IoIosCheckmarkCircleOutline
										style={{
											fontSize: '4em',
											color: '#52c41a',
											marginBottom: '2vh',
										}}
									/>
								</div>
								<h3 style={{ color: '#52c41a' }}>
									Your email is now verified.
								</h3>
								<Button basic onClick={emailVerifiedContinue}>
									Continue
								</Button>
							</div>
						)}
					</StyledContentContainer>
				)}
			</StyledContainer>
		</div>
	)
}

export default withRouter(VerifyEmail)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`

const StyledPendingContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
`

const StyledContentContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2em;
	max-width: 92vw;
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
