/* eslint-disable require-yield */
import { takeLatest } from 'redux-saga/effects'
import { startupTypes } from './redux'

/* ------------- Worker Sagas ------------- */
function* startupSaga() {
	//
}

/* ------------- Watcher Sagas ------------- */

const startupSagas = [takeLatest(startupTypes.STARTUP, startupSaga)]

export default startupSagas
