import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Button, message, PageHeader } from 'antd'
import { Form, FormItem, Input } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import flatten from 'flat'
import { AiOutlineUser } from 'react-icons/ai'
// import { logger } from '@monorepo/shared-client/utils'
import authActions from '../modules/auth/redux'
import { appGlobalTheme as theme } from '../app.json'
import { ReactComponent as Logo } from '../assets/images/logo.svg'

const { Title, Text } = Typography

const ResetPassword = (props) => {
	const dispatch = useDispatch()

	/// / Redux States
	const { user, resetPasswordWithEmailPending } = useSelector(
		(state) => state.auth
	)
	/// //////////////////////////////////////////
	/// / Local States
	/// //////////////////////////////////////////
	/// / Refs

	/// //////////////////////////////////////////
	/// / Handlers

	const onFinish = (values, { resetForm }) => {
		// logger.log('values:', values)
		dispatch(
			authActions.sendPasswordResetEmailRequestStart({
				data: {
					email: values.email,
					resetForm,
				},
			})
		)
		message.info('Email to reset password will be sent if account exists!', 8)
		resetForm()
	}
	const FormikPatchTouched = () => {
		const {
			errors,
			setFieldTouched,
			isSubmitting,
			isValidating,
		} = useFormikContext()
		useEffect(() => {
			if (isSubmitting && !isValidating) {
				// eslint-disable-next-line
				for (const path of Object.keys(flatten(errors))) {
					setFieldTouched(path, true, false)
				}
			}
		}, [errors, isSubmitting, isValidating, setFieldTouched])
		return null
	}

	const validateEmail = (value) => {
		let error
		if (!value) {
			error = 'Email is required!'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Invalid email address'
		}
		return error
	}

	const checkAuth = () => {
		if (user && user.email_verified) {
			const {
				location: { state = {} },
			} = props
			props.history.push({
				pathname:
					state.from && state.from.pathname
						? state.from.pathname
						: '/signedInHome',
				search: state.from && state.from.search ? state.from.search : '',
			})
		} else if (user) {
			props.history.push({
				pathname: '/emailVerificationRequired',
				state: props.location.state,
			})
		}
	}
	/// //////////////////////////////////////////
	/// / UseEffect Calls
	useEffect(() => {
		checkAuth()
		return () => {
			dispatch(authActions.clearAllAuthErrorsLocally())
		}
	}, [user]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<PageHeader
				className="site-page-header-responsive"
				title={
					<Link
						to={{
							pathname: '/',
							state: props.location.state,
						}}
					>
						<StyledLogo height={40} width={40} />
					</Link>
				}
				style={{
					height: '50px',
					position: window.screen.width >= 768 ? 'relative' : 'absolute',
				}}
			/>
			<StyledContainer>
				<Title
					className="noselect"
					level={2}
					style={{ marginTop: window.screen.width >= 768 ? '0' : '3vh' }}
				>
					Forgot Password?
				</Title>
				<Formik
					initialValues={{
						email: '',
					}}
					onSubmit={onFinish}
				>
					{() => (
						<StyledForm layout="vertical">
							<FormikPatchTouched />
							<div>
								<FormItem
									name="email"
									label={
										window.screen.width >= 768 ? (
											<span className="noselect">Email</span>
										) : null
									}
									labelalign="left"
									required
									validate={validateEmail}
								>
									<Input
										name="email"
										placeholder="Email"
										disabled={resetPasswordWithEmailPending}
										size="large"
										prefix={<AiOutlineUser />}
										style={{ width: '420px', maxWidth: '75vw' }}
									/>
								</FormItem>
								<Button
									type="primary"
									size="large"
									block
									htmlType="submit"
									loading={resetPasswordWithEmailPending}
									style={{ marginTop: '25px' }}
								>
									Request Reset Link
								</Button>
								<div
									className="noselect"
									style={{
										marginTop: '2vh',
										fontSize: '0.82rem',
										maxWidth: '82vw',
									}}
								>
									<Text type="secondary">
										<Link
											to={{
												pathname: '/signIn',
												state: props.location.state,
											}}
										>
											Sign-In Instead
										</Link>
									</Text>
								</div>
							</div>
						</StyledForm>
					)}
				</Formik>
			</StyledContainer>
		</div>
	)
}

export default withRouter(ResetPassword)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	min-height: 25vh;
	margin-top: 2vh;
	padding: 2rem;
	max-width: 92vw;
	box-shadow: ${() =>
		theme === 'dark'
			? ''
			: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'};
	background-color: ${() =>
		theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'inherent'};
`
const StyledLogo = styled(Logo)`
	cursor: pointer;
	fill: #00c805;
`
