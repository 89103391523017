import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'

const NoMatch = (props) => {
	return (
		<StyledContainer>
			<Link
				to={{
					pathname: '/',
					state: props.location.state,
				}}
			>
				Go Home!!
			</Link>
		</StyledContainer>
	)
}
export default withRouter(NoMatch)

const StyledContainer = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`
