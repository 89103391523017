import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/database'
import { APP_ENV_TYPES } from '@monorepo/cloud-functions/common/constants.js'
import { logger } from '@monorepo/shared-client/utils'

// // USE THIS CONFIG FOR Development DEPLOY
let config = {
	apiKey: 'AIzaSyDMZFV1q68t2G4t8zDekE0zn8A8k5jHLEY',
	authDomain: 'dev.leagueofbulls.com',
	databaseURL: 'https://leagueofbulls-dev-default-rtdb.firebaseio.com',
	projectId: 'leagueofbulls-dev',
	storageBucket: 'leagueofbulls-dev.appspot.com',
	messagingSenderId: '860075766355',
	appId: '1:860075766355:web:1320a58223255280144ca6',
	measurementId: 'G-P7C1982371',
}

if (process.env.REACT_APP_ENV === APP_ENV_TYPES.production) {
	// // USE THIS CONFIG FOR PRODUCTION DEPLOY
	config = {
		apiKey: 'AIzaSyBCDErZNQ0OvnLf1host6MyrL46rkfaOGA',
		authDomain: 'leagueofbulls.com',
		databaseURL: 'https://leagueofbulls-production-default-rtdb.firebaseio.com',
		projectId: 'leagueofbulls-production',
		storageBucket: 'leagueofbulls-production.appspot.com',
		messagingSenderId: '240846426345',
		appId: '1:240846426345:web:72b4f8c4450dce7d6d6078',
		measurementId: 'G-3V3YKTJ7ZH',
	}
}

firebase.initializeApp(config)

function HandleError(error) {
	throw error
}
// for local functions and firestore emulator use during development
const { hostname } = window.location
logger.log('hostname:', hostname)
if (hostname === 'localhost') {
	try {
		firebase.functions().useEmulator('localhost', 5000)
		// firebase.firestore().useEmulator('localhost', 8080) // local firestore emulator resets at every run
	} catch (error) {
		HandleError(error)
	}
}

// firebase.firestore().enablePersistence({ synchronizeTabs: true })

const functions = firebase.functions()
// Get a reference to the database service
const firestore = firebase.firestore()

const firebaseAuth = firebase.auth()

export { firebaseAuth, functions, firestore, firebase }
