import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SignedInHome from '../components/SignedInHome'
import AuthLoading from '../components/AuthLoading'
import SignedOutHome from '../components/SignedOutHome'
import ConnectedAccounts from '../components/ConnectedAccounts'
import SignIn from '../components/SignIn'
import SignUp from '../components/SignUp'
import ForgotPassword from '../components/ForgotPassword'
import EmailVerificationRequired from '../components/EmailVerificationRequired'
import AuthAction from '../components/AuthAction'

import NoMatch from '../components/NoMatch'
import ProtectedRoute from './ProtectedRoute'

const RootNavigation = () => {
	return (
		<Switch>
			<Route exact path="/" component={AuthLoading} />
			<ProtectedRoute path="/signedInHome" component={SignedInHome} />
			<ProtectedRoute path="/connectedAccounts" component={ConnectedAccounts} />
			<Route
				path="/emailVerificationRequired"
				component={EmailVerificationRequired}
			/>
			<Route path="/signedOutHome" component={SignedOutHome} />
			<Route path="/signIn" component={SignIn} />
			<Route path="/signUp" component={SignUp} />
			<Route path="/forgotPassword" component={ForgotPassword} />
			<Route path="/authAction" component={AuthAction} />
			{/* when none of the above match, <NoMatch> will be rendered */}
			<Route component={NoMatch} />
		</Switch>
	)
}
export default RootNavigation
