import { put, takeLatest, takeLeading, call } from 'redux-saga/effects'
import authActions, { authTypes } from './redux'
import {
	signUpUser,
	signInUser,
	sendSignInLinkToEmail,
	signOutUser,
	authStateChangedListener,
	isSignInWithEmailLink,
	sendPasswordResetEmail,
	authWithRobinhood,
} from './api'

/* ------------- Worker Sagas ------------- */
function* signUpUserSaga({ data }) {
	try {
		// sets loading to true
		yield put(authActions.signUpUserPending())
		// makes api call
		const response = yield call(signUpUser, data)
		yield put(authActions.signUpUserFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.signUpUserRejected(error))
	}
}

function* signInUserSaga({ data }) {
	try {
		// sets loading to true
		yield put(authActions.signInUserPending())
		// makes api call
		const response = yield call(signInUser, data)
		yield put(authActions.signInUserFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.signInUserRejected(error))
	}
}

function* sendSignInLinkToEmailSaga({ data }) {
	try {
		// sets loading to true
		yield put(authActions.sendSignInLinkToEmailPending())
		// makes api call
		const response = yield call(sendSignInLinkToEmail, data)
		yield put(authActions.sendSignInLinkToEmailFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.sendSignInLinkToEmailRejected(error))
	}
}

function* signOutUserSaga({ dispatch }) {
	try {
		// sets loading to true
		yield put(authActions.signOutUserPending())
		// makes api call
		const response = yield call(signOutUser, { dispatch })

		yield put(authActions.signOutUserFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.signOutUserRejected(error))
	}
}

function* authStateChangedListenerSaga({ dispatch }) {
	try {
		// sets loading to true
		yield put(authActions.authStateChangedListenerPending())
		// makes api call
		yield call(authStateChangedListener, { dispatch })
		yield put(authActions.authStateChangedListenerFulfilled())
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.authStateChangedListenerRejected(error))
	}
}

function* isSignInWithEmailLinkSaga() {
	try {
		// sets loading to true
		yield put(authActions.isSignInWithEmailLinkPending())
		// makes api call
		yield call(isSignInWithEmailLink)
		yield put(authActions.isSignInWithEmailLinkFulfilled())
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.isSignInWithEmailLinkRejected(error))
	}
}

function* sendPasswordResetEmailSaga({ data }) {
	try {
		const { resetForm } = data
		// sets loading to true
		yield put(authActions.sendPasswordResetEmailPending())
		// makes api call
		const response = yield call(sendPasswordResetEmail, data)

		yield put(authActions.sendPasswordResetEmailFulfilled(response))
		resetForm()
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.sendPasswordResetEmailRejected(error))
	}
}

function* authWithRobinhoodSaga({ data }) {
	try {
		// sets loading to true
		yield put(authActions.authWithRobinhoodPending())
		// makes api call
		const response = yield call(authWithRobinhood, data)
		yield put(authActions.authWithRobinhoodFulfilled(response))
	} catch (error) {
		// dispatch a failure action to the store with the error
		yield put(authActions.authWithRobinhoodRejected(error))
	}
}
/* ------------- Watcher Sagas ------------- */
const authSagas = [
	takeLatest(authTypes.SIGN_UP_USER_REQUEST_START, signUpUserSaga),
	takeLatest(authTypes.SIGN_IN_USER_REQUEST_START, signInUserSaga),
	takeLatest(
		authTypes.SEND_SIGN_IN_LINK_TO_EMAIL_REQUEST_START,
		sendSignInLinkToEmailSaga
	),
	takeLatest(authTypes.SIGN_OUT_USER_REQUEST_START, signOutUserSaga),
	takeLatest(
		authTypes.AUTH_STATE_CHANGED_LISTENER_REQUEST_START,
		authStateChangedListenerSaga
	),
	takeLatest(
		authTypes.IS_SIGN_IN_WITH_EMAIL_LINK_REQUEST_START,
		isSignInWithEmailLinkSaga
	),
	takeLeading(
		authTypes.SEND_PASSWORD_RESET_EMAIL_REQUEST_START,
		sendPasswordResetEmailSaga
	),
	takeLatest(
		authTypes.AUTH_WITH_ROBINHOOD_REQUEST_START,
		authWithRobinhoodSaga
	),
]
export default authSagas
