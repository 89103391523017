import { createActions, createReducer } from 'reduxsauce'
// import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	networkOnline: null,
	networkOffline: null,
})

export const networkTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
	online: true,
}

export const networkOnline = (state = INITIAL_STATE) => {
	return produce(state, (draft) => {
		draft.online = true
	})
}
export const networkOffline = (state = INITIAL_STATE) => {
	return produce(state, (draft) => {
		draft.online = false
	})
}

// map our action types to our reducer functions
export const HANDLERS = {
	[Types.NETWORK_ONLINE]: networkOnline,
	[Types.NETWORK_OFFLINE]: networkOffline,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
